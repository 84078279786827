import styles from './styles.module.css'
import BloudImg  from '../../assets/icons/bloud.png';
import PollImg  from '../../assets/icons/poll.png';
import ActImg  from '../../assets/icons/act.png';
import { useLocation , useNavigate } from "react-router"; 
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
export const CreatePostHeader=(props)=>{   

    let data = useLocation(); 
    let navigate= useNavigate();
    const dispatch = useDispatch(); 
    const [actTab, setActTab] = useState('bloud'); 
    const counter = useSelector((state) => state.posts.tabAct);
    const backBtn = () =>{
       if(data.state!==null && data.state.prevPath == '/posts'){
        navigate(-1)
       } else {
        navigate('/posts')
       }
    }
    useEffect(()=>{
        if(counter){
            setActTab(counter)
        }
    },[counter])
    const switchTab = (e,tab) => {
        e.preventDefault();
        setActTab(tab)
       dispatch({type: 'TAB',payload: tab})
    }
    return(
        <div className={styles.navBar}>
            <div className={styles.navbarInner}>
                <ul className={styles.dFlex}>
                    <li>
                        <a href="#" onClick={(e)=>{switchTab(e,'bloud')}} className={actTab == 'bloud' ? styles.active : null}>
                            <img src={BloudImg} alt="" />
                            <span>BLOUD</span>
                        </a>
                    </li>
                    <li>
                        <a href="#" onClick={(e)=>{switchTab(e,'poll')}} className={actTab == 'poll' ? styles.active : null}>
                            <img src={PollImg} alt="" />
                            <span>POLL</span>
                        </a>
                    </li>
                    <li>
                        <a href="#" onClick={(e)=>{switchTab(e,'act')}} className={actTab == 'act' ? styles.active : null}>
                            <img src={ActImg} alt="" />
                            <span>ACT</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div className={styles.closeIcon}>
                {/* <Link to='/posts'> */}
                    <span onClick={()=>{backBtn()}}>X</span>
                {/* </Link> */}
            </div>
        </div>
    )
}