import { useEffect, useRef } from 'react'; 
import styles from './styles.module.css';
import { FaFacebookSquare , FaGoogle } from 'react-icons/fa';
import appStore from '../../assets/images/appStore.png'
import { NavLink, useNavigate } from 'react-router-dom';
export const LoginPopup = (props) => { 
    function closeLoginPopup(){
        props.setLoginPopup(false)
    }
    return(
        <>
        <div className={styles.newLogin}>
        <div className={styles.close_div} onClick={()=>{closeLoginPopup()}}>
        X
        </div>
        <div className={styles.topSec}>
            <h2>Continue to meaningful Conversations</h2>
        </div>
        <div className={styles.midSec}>
            <p>Please use one of the following options</p>
            <div className={styles.loginSec}>
                <div className={styles.loginBtn}>
                <NavLink to={"/login"}>login to Continue</NavLink>
                </div>
                <span className={styles.divider}>or</span>
                <div className={styles.socialLogins}>
                    <a href="/auth/facebook/" className={styles.fbLogin}>
                    <FaFacebookSquare color="#4e6ea7" size="16" />
                        <span>Facebook</span>
                    </a>
                    
                    <a href="/auth/google" className={styles.googleLogin}>
                        <FaGoogle color="#DB4437" size="16" />
                        <span>Google</span>
                    </a>
                    
                </div>
                <div className={styles.signup}>
                    <p>Not on LoudST yet?  <NavLink to={"/singUp"}>Sign Up</NavLink></p>
                </div>
            </div>
         

        </div>
        <hr className={styles.dotted} />
        <div className={styles.appLink}>
            <p>Download, LoudST App</p>
            <a href="https://bit.ly/2T9TfwF">
                <img src={appStore} alt="" />
            </a>
        </div>
    </div>
    </>
    )
}