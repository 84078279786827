import { alertConstants } from '../constants/alert.constants';

export const alertActions = {
    success,
    error,
    clear,
    loader
};

function success(message) {
    return { type: alertConstants.SUCCESS, message };
}

function error(message) {
    return { type: alertConstants.ERROR, message };
}

function clear() {
    return { type: alertConstants.CLEAR };
}
function loader(message) {
    return { type: alertConstants.LOADER,message };
}