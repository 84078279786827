import React ,  { useEffect, useRef, useState } from 'react'; 
import styles from './styles.module.css'
import defaultImg from '../../../assets/icons/user-bloud.png'
import imgBck from '../../../assets/icons/img_back.png'
import { FaRegClock,FaThumbsUp } from 'react-icons/fa';
import { BsThreeDots } from 'react-icons/bs';
import userLike from '../../../assets/emticon/userLike.png'
import userLove from '../../../assets/emticon/userLove.png'
import userLaugh from '../../../assets/emticon/userLaugh.png'
import userShock from '../../../assets/emticon/userShock.png'
import userSad from '../../../assets/emticon/userSad.png'
import userAngry from '../../../assets/emticon/userAngry.png'
import userComment from '../../../assets/icons/user_comment.png'
import share from '../../../assets/icons/share.png'

import { useNavigate } from "react-router";
import  userImgDefault from '../../../assets/icons/user-bloud.png'
export const PostAuthor = (props) => {
    const navigate = useNavigate() 
    const [showDropdown,setShowDropdown]  = useState(false);
    const [dropDownClass,setdropDownClass]  = useState(false);
    const smileys =
        {'userLike':userLike,
        'userLove':userLove,
        'userLaugh':userLaugh,
        'userShock':userShock,
        'userSad':userSad,
        'userAngry':userAngry};
    // (function timeAgo(selector) {

    //     var templates = {
    //         prefix: "",
    //         suffix: " ago",
    //         seconds: "less than a minute",
    //         minute: "about a minute",
    //         minutes: "%d minutes",
    //         hour: "about an hour",
    //         hours: "about %d hours",
    //         day: "a day",
    //         days: "%d days",
    //         month: "about a month",
    //         months: "%d months",
    //         year: "about a year",
    //         years: "%d years"
    //     };
    //     var template = function (t, n) {
    //         return templates[t] && templates[t].replace(/%d/i, Math.abs(Math.round(n)));
    //     };

    //     var timer = function (time) {
    //         if (!time)
    //             return;
    //         time = time.replace(/\.\d+/, ""); // remove milliseconds
    //         time = time.replace(/-/, "/").replace(/-/, "/");
    //         time = time.replace(/T/, " ").replace(/Z/, " UTC");
    //         time = time.replace(/([\+\-]\d\d)\:?(\d\d)/, " $1$2"); // -04:00 -> -0400
    //         time = new Date(time * 1000 || time);

    //         var now = new Date();
    //         var seconds = ((now.getTime() - time) * .001) >> 0;
    //         var minutes = seconds / 60;
    //         var hours = minutes / 60;
    //         var days = hours / 24;
    //         var years = days / 365;

    //         return templates.prefix + (
    //             seconds < 45 && template('seconds', seconds) ||
    //             seconds < 90 && template('minute', 1) ||
    //             minutes < 45 && template('minutes', minutes) ||
    //             minutes < 90 && template('hour', 1) ||
    //             hours < 24 && template('hours', hours) ||
    //             hours < 42 && template('day', 1) ||
    //             days < 30 && template('days', days) ||
    //             days < 45 && template('month', 1) ||
    //             days < 365 && template('months', days / 30) ||
    //             years < 1.5 && template('year', 1) ||
    //             template('years', years)
    //         ) + templates.suffix;
    //     };

    //     var elements = document.getElementsByClassName('timeago');
    //     for (var i in elements) {
    //         var $this = elements[i];
    //         if (typeof $this === 'object') {
    //             $this.innerHTML = timer($this.getAttribute('title') || $this.getAttribute('datetime'));
    //         }
    //     }
    //     // update time every minute
    //     setTimeout(timeAgo, 1000);

    // })();
    const reactionRef = useRef()
   
  function handleOnClick(id,postType){
    navigate('/post/'+id,  {state: { prevPath: 'posts' }});
   }
 
    function showIcons(e){
        e.preventDefault(); 
        let iconList = document.getElementsByClassName(styles.smileys);
        let iconArray = [...iconList]
        if(!e.currentTarget.nextElementSibling.nextElementSibling.classList.contains(styles.show)){
            iconArray.forEach(ele => {
                if(ele.classList.contains(styles.show)){
                     ele.classList.remove(styles.show);
                }
            });
            e.currentTarget.nextElementSibling.nextElementSibling.classList.add(styles.show)
            document.addEventListener('click',(e)=>{ 
                iconArray.forEach(ele => {
                    if(ele.classList.contains(styles.show)){
                         ele.classList.remove(styles.show);
                    }
                });
                document.removeEventListener('click',function(){})
            })
        } else {
            e.currentTarget.nextElementSibling.nextElementSibling.classList.remove(styles.show)
        }
      
        e.stopPropagation();

    }
    function closeDropdown(e){
        e.preventDefault();
        setdropDownClass(false);
        setTimeout(()=>{
            setShowDropdown(false);
        },500)
        e.stopPropagation();
    }
    function dropdownToggle(e){
        e.preventDefault();
        setShowDropdown(true);
        setTimeout(()=>{
            setdropDownClass(true)
        },50) 
        e.stopPropagation();
    }
     
 
    function postReaction(reaction,e){
        e.preventDefault();
        // props.postReaction(props.post.currUser,props.post._id,reaction)
    }
    
      

    return(
        <>
    <div className={props.page == "singlePost"  ? [styles.userProfile , styles.singlePage].join(' ') : styles.userProfile  }>
        <div className={styles.profileLeft}>
            <div className={styles.profileImg}>
                <img className={styles.backImg} src={imgBck} alt=""/>
                <img className={styles.user_img} src={props.post.postUserProfile ? props.post.postUserProfile : defaultImg} alt=""/>
            </div>
            <div className={styles.userDetails}>
                <strong>{props.post.postUserName}</strong>
                <div className={styles.postTime}>
                <FaRegClock /> 
                     <p>{props.post.timeAgo}</p>
                    </div> 
                    
            </div>
        </div>
        <div className={styles.profileRight}>
        <p className={styles.status_show}>Public</p>
        </div>
       
    </div>
    
    </>
    )
}