let apiUrl = "https://backend.loudst.com";
// export const apiUrl = "http://localhost:8889";
export const API = {
 LOGIN_MOBILE:apiUrl+'/api/web/user/loginWithMobile',
 LOGIN_EMAIL:apiUrl+'/api/web/user/loginWithEmail',
 SIGNUP_MOBILE:apiUrl+'/api/web/user/signUpWithMobile',
 SIGNUP_EMAIL:apiUrl+'/api/web/user/signUpWithEmail',
 VERIFYOTP:apiUrl+'/api/web/user/mobileOtpVerify',
 GET_POSTS:apiUrl+'/api/app/post/getNewPostList',
 VOTE_POLL:apiUrl+'/api/app/post/polling',
 EVENT_VOTE:apiUrl+'/api/web/post/eventParticipate',
 POST_REACTION:apiUrl+'/post/reaction',
 GET_CATEGORIES: apiUrl+'/getCategoryList',
 ADD_POST: apiUrl+'/api/web/post/addNewPost',
 UPLOAD_IMAGE: apiUrl+'/api/web/upload-media/images',
 UPLOAD_VIDEO: apiUrl+'/api/web/upload-media/video',
 LINK_PREVIEW: apiUrl+'/getLinkData',
 VOTE_POSITIVE: apiUrl+'/api/web/post/agree',
 VOTE_NEGATIVE: apiUrl+'/api/web/post/disagree',
 GET_POST_ITEM: apiUrl+'/api/web/post/getPostItem',
 GET_COMMENTS: apiUrl+'/api/web/argument/list',
 ADD_COMMENT: apiUrl+'/api/web/argument/new',
 DELETE_COMMENT: apiUrl+'/api/web/argument/delete',
 RATE_COMMENT: apiUrl+'/api/web/argument/rating'
}