import React from 'react';
import styles from "./styles.module.css";
import Default from '../../../assets/icons/default.png'
import { apiUrl } from '../../../constants/api.constants';
const PostMedia = function (props) { 
  function getImages(imgs) {
   
    let imgHTML = [];
    {
      imgs.forEach((img, i) => {
        imgHTML.push(
          <div key={i} className={imgs.length == 2 ? styles.col2 : styles.col1}>
            <img src={img} alt=""
             onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src=Default;
            }}
            />
          </div>
        );
      });
    }
    return imgHTML;
  }

  return (
    <> 
      {props.postMedia.length > 0 && (
        <div
          className={
            props.page == "singlePost"
              ? [styles.postmages2, styles.postmages].join(" ")
              : styles.postmages
          }
        >
          {getImages(props.postMedia)}
        </div>
      )} 
      {props.postVideo.video && (
        <div className={styles.postVideo}>
        <video preload="true" controls poster={props.postVideo.videoThumbnail}>
          <source src={props.postVideo.video} type="video/mp4"></source>
        </video>
        </div>
      )}
      {props.linkObj.linkPreviewDescription && <div className={styles.linkPreviewDesc}>
        {props.linkObj.linkPreviewDescription}
      </div>}
    </>
  );
};

export default PostMedia;
