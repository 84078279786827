import { API} from '../constants/api.constants'
const postServices = {
    getPosts,
    postPoll,
    eventVote,
    postReaction,
    getCategories, 
    addPost,
    uploadImage,
    previewLink,
    postVoting,
    getPostItem,
    getComments,
    addComment,
    deleteComment,
    uploadVideo,
    commentRating,
};
async function getPosts(body) { 
    let requestOptions;
    if(body.userId){
        requestOptions  = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' ,  'Authorization':'Bearer '+ body.userId },
            body: JSON.stringify(body)
        };
    } else {
        requestOptions  = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'  },
            body: JSON.stringify(body)
        };
    }

    const response = await fetch(API.GET_POSTS, requestOptions)
    let posts;
   if(!response.ok){ 
        throw new Error('Network response was not ok'); 
   } else {
        posts = await handleResponse(response);
   }
    return posts;
}
async function deleteComment(cid,userId) {
    const requestOptions = {
        "async": true,
        "crossDomain": true, 
        "method": "POST",
        headers: { 'Content-Type': 'application/json' ,  'Authorization':'Bearer '+ userId },
        body: JSON.stringify({"argunment_id": cid}) 
    };
    const response = await fetch(API.DELETE_COMMENT, requestOptions);
    const postResult = await handleResponse(response);
    return postResult;
}
async function commentRating(cid,rating ,pid,userId) {
    const requestOptions = {
        "async": true,
        "crossDomain": true, 
        "method": "POST",
        headers: { 'Content-Type': 'application/json' ,  'Authorization':'Bearer '+ userId },
        body: JSON.stringify({"post_id":pid,"argunment_id": cid , "rating":rating}) 
    };
    const response = await fetch(API.RATE_COMMENT, requestOptions);
    const postResult = await handleResponse(response);
    return postResult;
}


async function addComment(postId,text,userId,pid) {
    const requestOptions = {
        "async": true,
        "crossDomain": true, 
        "method": "POST",
        headers: { 'Content-Type': 'application/json' ,  'Authorization':'Bearer '+ userId },
        body: JSON.stringify({"post_id":postId,"text": text,"replay_argunment_id": pid}) 
    };
    const response = await fetch(API.ADD_COMMENT, requestOptions);
    const postResult = await handleResponse(response);
    return postResult;
}

async function getPostItem(postId,userId) {
    const requestOptions = {
        "async": true,
        "crossDomain": true, 
        "method": "POST",
        headers: { 'Content-Type': 'application/json' ,  'Authorization':'Bearer '+ userId },
        body: JSON.stringify({"post_id":postId}) 
    };
    const response = await fetch(API.GET_POST_ITEM, requestOptions);
    const postResult = await handleResponse(response);
    return postResult;
}

async function getComments(type,postId,userId) { 
    const requestOptions = {
        "async": true,
        "crossDomain": true, 
        "method": "POST",
        headers: { 'Content-Type': 'application/json' ,  'Authorization':'Bearer '+ userId },
        body: JSON.stringify({"post_id":postId ,"list_type":type }) 
    };
    const response = await fetch(API.GET_COMMENTS, requestOptions);
    const postResult = await handleResponse(response);
    return postResult;
}

async function postPoll(userId , pollId) {  
    const requestOptions = {
        "async": true,
        "crossDomain": true, 
        "method": "POST",
        "headers": {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':'Bearer '+ userId
        }, 
        body: JSON.stringify({"poll_id":pollId})
    };

    const response = await fetch(API.VOTE_POLL, requestOptions);
    const postResult = await handleResponse(response);
    return postResult;
    // return {isSuccess:1,message:"Success",response:{}}
    
}
async function eventVote(userId, postId , attending) { 
    const requestOptions = {
        "async": true,
        "crossDomain": true, 
        "method": "POST",
        headers: { 'Content-Type': 'application/json' ,  'Authorization':'Bearer '+ userId },
        body: JSON.stringify({"post_id":postId,"next_state":attending}) 
    };
    const response = await fetch(API.EVENT_VOTE, requestOptions);
    const postResult = await handleResponse(response);
    return postResult;
    // return {isSuccess:1,message:"Success",response:{}}
    
}
async function postReaction(currUser,postId,typereaction) {
    var data = JSON.stringify({ 'userId': currUser, 'postId': postId, 'typeReaction': typereaction });
    var requestOptions = {
        "async": true,
        "crossDomain": true, 
        "method": "POST",
        "headers": {},
        body: data
    }
    const response = await fetch(API.POST_REACTION, requestOptions);
    const postResult = await handleResponse(response);
    return postResult;
}

async function getCategories(currUser){ 
    // var data = JSON.stringify({ 'user': currUser });
    // var requestOptions = {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     body: data
    // }
    // const response = await fetch(API.GET_CATEGORIES, requestOptions);
    // const categoryList = await handleResponse(response);
    let categoryList = []
    return categoryList;
}

async function addPost(postObj){ 
    const postResponse =  await fetch(API.ADD_POST, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':'Bearer '+ postObj.userId
        },
        body: JSON.stringify(postObj)
    })
    const response = await handleResponse(postResponse);
    return response;
}
async function uploadImage(file ,userId){ 
    for (const value of file.values()) {
        console.log(value);
      }
    const response =  await fetch(API.UPLOAD_IMAGE, {
        method: 'POST',
        headers: { 
            // 'Content-Type': 'multipart/form-data', 
            'Authorization':'Bearer '+ userId
        },
        body:file
    })
    const uploadResp = await handleResponse(response);
    return uploadResp;
}
async function uploadVideo(file ,userId){ 
    for (const value of file.values()) {
        console.log(value);
      }
    const response =  await fetch(API.UPLOAD_VIDEO, {
        method: 'POST',
        headers: { 
            // 'Content-Type': 'multipart/form-data', 
            'Authorization':'Bearer '+ userId
        },
        body:file
    })
    const uploadResp = await handleResponse(response);
    return uploadResp;
}

async function previewLink(url){

   
    var data = {q: url}

   const response = await fetch('https://api.linkpreview.net', {
    method: 'POST',
    headers: {
        'X-Linkpreview-Api-Key': '7677f57840e8d30b71fb689fd1cab71c',
    },
    mode: 'cors',
    body: JSON.stringify(data),
    })
    const uploadResp = await handleResponse(response);
    return uploadResp;
}
async function postVoting(userId,postId,voteType){
    let api = "";
    if(voteType=='agree'){
        api = API.VOTE_POSITIVE;
    } else {
        api = API.VOTE_NEGATIVE;
    }
    const body = { 
        post_id: postId
    };
    const data = await fetch(api, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':'Bearer '+ userId
        },
        body: JSON.stringify(body)
    })
    const uploadResp = await handleResponse(data);
    return uploadResp;
     
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 425) {
               
                return data;
                
            }
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                
            }
         

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

export default postServices;