export const postConstants = {
    GET_ALL: 'GET_ALL_POSTS',
    POST_LOADER: 'POST_LOADER',
    POST_SUCCESS: 'POST_SUCCESS',
    POST_FAILURE: 'POST_FAILURE',
    POLLING_SUCCESS: 'POLLING_SUCCESS',
    EVENT_SUCCESS: 'EVENT_SUCCESS',
    POST_REACTION: 'POST_REACTION',
    POST_VOTE: 'POST_VOTE',
    TAB: 'TAB',
};
