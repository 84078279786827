import React , { useState, useEffect,useRef,useCallback }from 'react'; 
import { connect,useSelector } from 'react-redux';  
import styles from './styles.module.css';
import {CreatePostHeader} from '../../components/Headers/CreatePostHeader' 
import {BloudPost} from '../../components/createPostFormat/Bloud/BloudPost' 
import {ActPost} from '../../components/createPostFormat/Act/ActPost' 
import {PollPost} from '../../components/createPostFormat/Poll/PollPost' 
// import {PostSetting} from '../../components/createPostFormat/Settings'
import {Footer} from '../../components/createPostFormat/Footer/Footer'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
let s = 0

const CreatePost = (props) =>{   
    const data = useSelector((state) => state.posts)
    const [userName, setuserName] = useState('');
    const [formSubmited, setFormSubmited] = useState(false); 
    const [userId, setUserId] = useState('000000000000000000000000'); 
    const [imgs, setImgs] = useState(null); 
    const [vid, setVid] = useState(null); 
    const [videoThumb, setVideoThumb] = useState(null); 

useEffect(()=>{  
    if(props.authentication.user){
    setUserId(props.authentication.user._id) 
    setuserName(props.authentication.user.name)
    } else {
        setuserName('Guest')
    }
  
},[props.authentication.user])

function buttonClick( formType,img , vid ,vidThumb){ 
  console.log(vid, 'vid')
    setFormSubmited(true)
    setImgs(img)
    setVid(vid)
    setVideoThumb(vidThumb)
}
function feedbackFunc(){
  setFormSubmited(false)
}

  return(
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={styles.container}>
          <CreatePostHeader />
          <div className={styles.pageWrap} > 
            {data.tabAct == 'bloud' && <BloudPost submitForm={formSubmited} formFeedback={feedbackFunc} userData = {props.authentication.user} videoFiles={vid} vidThumb={videoThumb} imgFiles={imgs} /> }
            {data.tabAct == 'poll' && <PollPost submitForm={formSubmited} formFeedback={feedbackFunc} userData = {props.authentication.user} />}
            {data.tabAct == 'act' && <ActPost submitForm={formSubmited} formFeedback={feedbackFunc} userData = {props.authentication.user} videoFiles={vid} vidThumb={videoThumb} imgFiles={imgs} />}
            {/* <PostSetting clickBtn ={buttonClick}/> */}
            <Footer submitform={buttonClick} userData =  {props.authentication.user} />
          </div> 
      </div>
    </LocalizationProvider>
  );
}
function mapState(state) {
    const {  authentication , alert} = state; 
    return { authentication ,alert};
}

const connectedCreatePost = connect(mapState)(CreatePost);
export { connectedCreatePost as CreatePost };
