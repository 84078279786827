import { userConstants } from '../constants/user.constants';

export function users(state = {showpopup:false}, action) {
  switch (action.type) {
    case userConstants.SHOW_ALL:
      return {
        showpopup: true
      }
  }
  return null;
}