import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import {
  FaMapMarkerAlt,
  FaCalendarAlt,
  FaRegClock,
  FaCheck,
} from "react-icons/fa";
import target from "../../../assets/icons/target.png";
import doubleTick from "../../../assets/icons/double-tick.png";
import { Modal } from "../../modal";
import postServices from "../../../services/post.services";
import { useRef } from "react";
const EventPost = (props) => {
  const [modal, setModal] = useState(false);
  const [attendingStatus, setAttendingStatus] = useState(""); 
  const [updateStatus, setUpdateStatus] = useState(false); 

  function eventVote(attending, e) {
    e.preventDefault();
    setModal(true);
    setAttendingStatus(attending);
  }
  function confirmVote() {
    postServices
      .eventVote(
        props.postDetail.currUser,
        props.postDetail.postId,
        attendingStatus
      )
      .then((resp) => {
        if (resp.status) {
            setUpdateStatus(true)
        }
        setModal(false);
      });
  }

  useEffect(()=>{ 
    if(props.postDetail.eventData.isParticipate){
        setUpdateStatus(true)
    } else{
        setUpdateStatus(false)
    }
  },[props.postDetail])

  function closeModal(modalState) {
    setModal(false);
  }

  //   let totalCount=  props.postDetail.eventData.notgoingCount + props.postDetail.eventData.goingCount;
  let totalCount = 0;

  return (
    <>
      <div className={styles.singlePost}>
        <Modal visible={modal} onClose={closeModal}>
          <div className={styles.modalInner}>
            <h4>
              Sure, right?
              <br />
              You cannot change your choice later.
            </h4>
            <div className={styles.buttons}>
              <button
                onClick={(e) => {
                  confirmVote(e);
                }}
              >
                Yes
              </button>
              <button
                onClick={(e) => {
                  closeModal(e);
                }}
                id="No"
              >
                No
              </button>
            </div>
          </div>
        </Modal>
        <div className={styles.eventPost}>
          <h1>{props.postDetail.eventData.eventName}</h1>
          <ul className={styles.eventDetails}>
            {props.postDetail.eventData.eventAddress && (
              <li>
                <FaMapMarkerAlt />
                <span>{props.postDetail.eventData.eventAddress}</span>
              </li>
            )}
            {props.postDetail.eventData.onlinePetitionTo && (
              <li>
                <span style={{ marginLeft: 0 }}>
                  To, {props.postDetail.eventData.onlinePetitionTo}
                </span>
              </li>
            )}
            <li>
              <FaCalendarAlt />
              <span>{props.postDetail.eventData.eventExpiryDate}</span>
            </li>
            {props.postDetail.eventData.eventTimeFrom &&  props.postDetail.eventData.eventTimeFrom != 'Invalid date' && (
              <li>
                <FaRegClock />
                <span>{props.postDetail.eventData.eventTimeFrom} - {props.postDetail.eventData.eventTimeTo}</span>
              </li>
            )}
          </ul>
          {props.postDetail.eventData.eventType == "online petition" &&
            !props.postDetail.eventData.isEventExpires && (
              <>
                <div className={styles.actionBtns}>
                  {!updateStatus && (
                    <button onClick={(e) => eventVote(4, e)}>Sign</button>
                  )}
                  {updateStatus && (
                     <div className={styles.txtGoing}>
                         <h2><FaCheck /><span>Signed</span></h2>
                     </div> 
                  )}
                </div>
              </>
            )}
            {props.postDetail.eventData.eventType == "offline event" &&
            props.postDetail.eventData.expiry != 1 && (
                <div className={styles.actionBtns}>
              {!updateStatus && (  
                 <button onClick={(e) => eventVote(2, e)}>I'll Join</button>
                )}
                 {updateStatus && (
                     <div className={styles.txtGoing}>
                         <h2><FaCheck /><span>I am going</span></h2>
                     </div> 
                  )}
              </div>
            )}
          {props.postDetail.eventData.notgoing == 1 &&
            props.postDetail.eventData.expiry != 1 && (
              <div className={styles.txtGoing}>
                <h2>
                  <FaCheck />
                  <span> I am not going</span>
                </h2>
              </div>
            )}
          <div className={styles.signInfo}>
            <div className={styles.signed}>
              <img src={doubleTick} />{" "}
              <span>
                {props.postDetail.eventData.userParticipationCountLabel}
              </span>
            </div>
            <div className={styles.signsRequired}>
              <img src={target} />
              <span>{props.postDetail.eventData.targetMinimunLabel}</span>
            </div>
          </div>
          
          {props.postDetail.eventData.isEventExpires && (
            <h3 className={styles.text_expired}> Event has expired</h3>
          )}
        </div>
      </div>
    </>
  );
};

export default EventPost;
