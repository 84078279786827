import { userConstants } from '../constants/user.constants'
import  postServices from '../services/post.services';
import { alertActions } from './alert.actions';
import { history } from '../helpers/history'; 
import { postConstants } from '../constants/posts.constants';
export const postActions = {
    getPosts, 
    polling,
    eventConfirmation,
    postReaction,
    postVote
};

function getPosts(userId, limit , skip ,filter) { 
    return dispatch => { 
        dispatch(alertActions.loader(true));
        postServices.getPosts(userId, limit , skip ,filter)
            .then(
                posts => {  
                    if(posts.isSuccess == 1){ 
                        dispatch(alertActions.loader(false));
                        dispatch(success(posts.response,false));  
                    } else if(posts.isSuccess == 0){
                        dispatch(failure(posts.message.toString()));
                    }
                   
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
            
                }
            );
    };

    function getAll() { return { type: postConstants.GET_ALL} }
    function success(posts) { return { type: postConstants.POST_SUCCESS, posts } }
    function failure(error) { return { type: postConstants.POST_FAILURE, error } }
}

function polling(userId,postId,pollId){ 
    return dispatch => { 
      postServices.postPoll(userId, postId , pollId)
            .then(
                pollResult => { 
                  
                    if(pollResult.isSuccess == 1){  
                        dispatch(success(postId,pollId));  
                    } else if(pollResult.isSuccess == 0){
                        // dispatch(failure(pollResult.message.toString()));
                    }
                   
                },
                error => {
                    // dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
            
                }
            );
    };

    function success(postId,pollId) { return { type: postConstants.POLLING_SUCCESS,postId,pollId } }
    // function failure(error) { return { type: postConstants.POST_FAILURE, error } }
}

function eventConfirmation(userId,postId,attending){ 
        return dispatch => { 
          postServices.eventVote(userId, postId , attending)
                .then(
                    eventResult => { 
                      
                        if(eventResult.isSuccess == 1){  
                            dispatch(success(userId,postId,attending));  
                        } else if(eventResult.isSuccess == 0){
                            // dispatch(failure(pollResult.message.toString()));
                        }
                       
                    },
                    error => {
                        // dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                
                    }
                );
        };
    
        function success(userId,postId,attending) { return { type: postConstants.EVENT_SUCCESS,userId,postId,attending} }
        // function failure(error) { return { type: postConstants.POST_FAILURE, error } }
    }

    
function postReaction(userId,postId,typereaction){ 
    return dispatch => { 
      postServices.postReaction(userId, postId , typereaction)
            .then(
                eventResult => { 
                  
                    if(eventResult.isSuccess == 1){  
                        dispatch(success(userId,postId,typereaction));  
                    } else if(eventResult.isSuccess == 0){
                        // dispatch(failure(pollResult.message.toString()));
                    }
                   
                },
                error => {
                    // dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
            
                }
            );
    };

    function success(userId,postId,typereaction) { return { type: postConstants.POST_REACTION,userId,postId,typereaction} }
    // function failure(error) { return { type: postConstants.POST_FAILURE, error } }
}
function postVote(userId,postId,voteType){ 
    return dispatch => { 
      postServices.postVoting(userId, postId , voteType)
            .then(
                eventResult => { 
                  
                    if(eventResult.isSuccess == 1){  
                        // dispatch(success(userId,postId,voteType));  
                    } else if(eventResult.isSuccess == 0){
                        // dispatch(failure(pollResult.message.toString()));
                    }
                   
                },
                error => {
                    // dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
            
                }
            );
    };

    function success(userId,postId,voteType) { return { type: postConstants.POST_VOTE,userId,postId,voteType} }
    // function failure(error) { return { type: postConstants.POST_FAILURE, error } }
}



