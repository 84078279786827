import { postConstants } from '../constants/posts.constants';
let initialState = { posts: [], tabAct:'bloud' }
export function posts(state = initialState, action) {
  let posts = [];
  let p = "";
  switch (action.type) {
    case postConstants.GET_ALL:
      return {
        posts: state.posts ? [...state.posts] : []
      }
    case postConstants.POST_SUCCESS:
      return {
        ...state,
        posts: state.posts ? [...state.posts, ...action.posts] : []
      }
    case postConstants.POST_FAILURE:
      return {
        error: action.error
      };
    case postConstants.POLLING_SUCCESS:
      posts = [...state.posts];
      p = posts.find(e => {
        return e._id == action.postId ? e : null;
      });
      let pollClicked = p.poll.find(poll => {
        return poll._id == action.pollId ? poll : null;
      })
      let pollCountsUpdate = p.pollCounts + 1;
      p.pollCounts = pollCountsUpdate;
      p.votingDone = 1;
      pollClicked.count = pollClicked.count + 1;
      p.poll.map(polls => {
        polls.voted = ((polls.count / pollCountsUpdate) * 100).toFixed(0)
      })
      return {
        posts: [...state.posts]
      };
    case postConstants.EVENT_SUCCESS:
      posts = [...state.posts];
      p = posts.find(e => {
        return e._id == action.postId ? e : null;
      });
      if (action.attending == 1) {
        p.event.going = 1;
        p.event.goingCount = p.event.goingCount + 1;
        p.event.goingDetails.push({
          time: Date.now(), user: action.userId
        })
      } else if (action.attending == 0) {
        p.event.notgoing = 1;
        p.event.notgoingCount = p.event.notgoingCount + 1;
        p.event.notgoingDetails.push({
          time: Date.now(), user: action.userId
        })
      }
    case postConstants.POST_REACTION:
      posts = [...state.posts];

      p = posts.find(e => {
        return e._id == action.postId ? e : null;
      });
      const initialReact = p.isReaction;
      if (initialReact == 1) {
        p.isReaction = 0;
        p.reactionCount = p.reactionCount - 1
      } else if (initialReact == 0) {
        p.isReaction = 1;
        p.reactionCount = p.reactionCount + 1
      }
      switch (action.typereaction) {
        case 1:
          if (initialReact == 1) {
            p.like = 0;
            p.reactionType = ""
          } else {
            p.like = 1;
            p.reactionType = "userLike";
          }
          break;
        case 2:
          if (initialReact == 1) {
            p.love = 0;
            p.reactionType = ""
          } else {
            p.love = 1;
            p.reactionType = "userLove"
          }
          break;
        case 3:
          if (initialReact == 1) {
            p.laugh = 0;
            p.reactionType = ""
          } else {
            p.laugh = 1;
            p.reactionType = "userLaugh"
          }

          break;
        case 4:
          if (initialReact == 1) {
            p.shock = 0;
            p.reactionType = ""
          } else {
            p.shock = 1;
            p.reactionType = "userShock"
          }
          break;
        case 5:
          if (initialReact == 1) {
            p.sad = 0;
            p.reactionType = ""
          } else {
            p.sad = 1;
            p.reactionType = "userSad"
          }
          break;
        case 6:
          if (initialReact == 1) {
            p.angry = 0;
            p.reactionType = ""
          } else {
            p.angry = 1;
            p.reactionType = "userAngry"
          }

          break;
      }
      return {
        posts: [...state.posts]
      };
    case postConstants.TAB:
    {
      return {...state,tabAct:action.payload}
    }
    case postConstants.POST_VOTE:
      posts = [...state.posts];

      p = posts.find(e => {
        return e._id == action.postId ? e : null;
      });
      let isUser = true;
      if(action.voteType=="agree"){
        // isUser =  p.votes.find(e=>{
        //   return e._id == action.user ? e : false;
        // })
        // if(!isUser){
        //   p.postPositiveVoteCount = p.postPositiveVoteCount  + 1
        // }
      } else {
        // if(!isUser){
        //   p.postNegativeVoteCount = p.postNegativeVoteCount  + 1
        // }
      }
 
    {
      return {posts: [...state.posts]}
    }
    default:
      return state
  }
}