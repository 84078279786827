import { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom'; 
import { connect } from 'react-redux';
import logo from '../../assets/icons/login-top-bg.jpg';
import styles from './styles.module.css';
import { userActions } from '../../actions/user.actions';
import 'react-phone-number-input/style.css'
import { parsePhoneNumber } from 'react-phone-number-input'
import PhoneInput   from 'react-phone-number-input'
import { FaFacebookSquare, FaTwitter, FaGoogle } from 'react-icons/fa';
import { userServices } from '../../services/user.services';
import localStorage from 'redux-persist/es/storage';
import editIcon from '../../assets/icons/editicon.png'

const LoginPage = (props) => {
  const navigate = useNavigate()
  const [userEmail, setuserEmail] = useState('ash@gmail.com');
  const [loginDetail, setloginDetail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [activeTab, setactiveTab] = useState('email');
  const [phoneNumber, setphoneNumber] = useState();
  const [loginType, setloginType] = useState(1);
  const [otpsent, setOtpsent] = useState(false);
  const [otpError, setOtpError] = useState('');
  const [isNewUser, setIsNewUser] = useState(false);
  const [otpRec, setOtpRec] = useState('');

  function handleChange(e) {
    const { name, value } = e.target;
    if (name == "email") {
      setuserEmail(value);
    }
  }

  function verifyOtp(e) {
    e.preventDefault();
    let otpBody = {}
    if (e.target[0].value) {
      localStorage.getItem('otp_session').then(otp_sess=>{
        if(otp_sess && otp_sess!=null){
          otpBody = { 'user_otp': e.target[0].value, 'otp_session': otp_sess }
          userServices.verifyOTP(otpBody).then(res=>{
            if(res.status){
              localStorage.removeItem('otp_session')
              localStorage.removeItem('expiry')
              localStorage.removeItem('loginDetail')
              props.login(res.response) 
              setOtpError('')
            } else{ 
            setOtpError('The code did not match')
            }
          })
        }
      })  

    } 
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    let loginDetail, userInp;
    if (loginType == 1 && userEmail) { 
      userInp = userEmail;
      loginDetail = {  "user_email":userInp  }
    } else {
      userInp = phoneNumber 
      const phoneInp = parsePhoneNumber(phoneNumber) 
      loginDetail = {'country_code':'+'+phoneInp.countryCallingCode,'user_mobile':phoneInp.nationalNumber} 
     
    }
    setloginDetail(userInp)
    localStorage.setItem('loginDetail', userInp); 
    userServices.login(loginDetail, loginType)
      .then(
        user => {
          if (user.status) {
            if (!user.is_new_user) {
              localStorage.setItem('otp_session', user.response.otp_session);
              localStorage.setItem('expiry', Date.now()); 
              setOtpsent(true)
              setIsNewUser(false)
              setOtpRec(user.response.OTP)
            } else {
              setIsNewUser(true)
              setOtpsent(false)
            }
          } else {
            
          }
        },
        error => {

        }
      );
  }
  useEffect(() => {
    if(props.loggedIn){
      navigate('/posts')
    }
   },[props.loggedIn])
  useEffect(() => {  
    localStorage.getItem('expiry').then(e => {
      if (e != null) {
        if (e && Date.now() - e > 10 * 60 * 1000) { // 10 minutes
          localStorage.removeItem('otp_session')
          localStorage.removeItem('expiry')
          setOtpsent(false)
        } else {
          setOtpsent(true)
        }
      } else {
        setOtpsent(false)
      }
    })
    localStorage.getItem('loginDetail').then(u=> {
      setloginDetail(u)
    })
  }, [])
  function changeTabs(tab) { 
    if (otpsent) return;
    setactiveTab(tab);
    setSubmitted(false);
    if (tab == 'email') {
      setloginType(1);
    } else {
      setloginType(2);
    }
  }
  function changeLogin(e){
    e.preventDefault()
    localStorage.removeItem('otp_session')
    localStorage.removeItem('expiry')
    localStorage.removeItem('loginDetail')
    setOtpsent(false)
  }

  const { loggingIn, error } = props


  return (
    <div className={styles.loginPage}>
      <div className={styles.container}>
        <div className={styles.loginBg}>
          <img src={logo} alt="" />
        </div>
       
        <div className={styles.loginTabs}>
          <ul>
            <li onClick={() => { changeTabs('email') }} className={activeTab == 'email' ? styles.active : null}>Email</li>
            <li onClick={() => { changeTabs('mobile') }} className={activeTab == 'mobile' ? styles.active : null}>Mobile</li>
          </ul>
        </div>
        {otpsent && <div className={styles.otpInfo}>
         <p>We have sent you a confirmation code on <span>{loginDetail}</span> <a href='#'  onClick={changeLogin}><img src={editIcon} alt=""/></a></p>
        </div>}
        {isNewUser && <div className={styles.otpInfo}>
         <p><span>{loginDetail}</span> is not registered with us <NavLink to={'/signUp'} ><span>Signup?</span></NavLink></p>
        </div>}
       
        <div className={styles.formSec}>
          {!otpsent && <form name="form" onSubmit={handleSubmit}>
            {activeTab == 'mobile' &&
              <div className={styles.formGroup + " " + (submitted && !phoneNumber ? styles.hasError : '')}>
                <PhoneInput 
                  defaultCountry="IN"
                  placeholder="Mobile number*"
                  value={phoneNumber}
                  onChange={setphoneNumber} />
                {submitted && !phoneNumber &&
                  <div className={styles.errorDiv}>required</div>
                }
              </div>
            }
            {activeTab == 'email' &&
              <div className={styles.formGroup + " " + (submitted && !userEmail ? styles.hasError : '')}>
                <input type="email" placeholder="Email ID*" className="form-control" name="email" value={userEmail} onChange={handleChange} />
                {submitted && !userEmail &&
                  <div className={styles.errorDiv}> required</div>
                }
              </div>
            }

            <div className={styles.rememberMe}>
              <input type="checkbox" name="rememberMe" value="rememberMe" id="rememberMe" />
              <label htmlFor="rememberMe">remember me</label>
            </div>
            <div className={styles.formGroup}>
              <button className={styles.btn}>Get OTP</button>
              {loggingIn &&
                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              }
            </div>
            {error && <div className={[styles.alert, styles.alertDanger].join(' ')}>{error}</div>}
            {/* <Link to="/posts" className="btn btn-link">CREATE NEW ACCOUNT</Link> */}
          </form>}
          {otpsent && <form name="otpVerify" onSubmit={verifyOtp}>
            <div className={styles.formGroup}>
              <input type="text" placeholder="OTP" value={otpRec} className="form-control" name="otp" />

            </div>
             {otpError && <div className={styles.otpInfo}>
              <p><span>{otpError}</span></p>
              </div>}
            <div className={styles.formGroup}>
              <button className={styles.btn}>LOGIN</button>
              {loggingIn &&
                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              }
            </div>
          </form>}
        </div>
       
        <div className={styles.separator}><span>OR</span></div>
        <div className={styles.socialLogin}>
          <a href="#" className={styles.fb}><FaFacebookSquare color="#4e6ea7" size="12" /><span>facebook</span></a>
          {/* <a href="#" className={styles.twitter}><FaTwitter color="#55adee" size="12" /><span>twitter</span></a> */}
          <a href="#" className={styles.google} ><FaGoogle color="#DB4437" size="12" /><span>Google</span></a>
        </div>
        <div className={styles.signup}>
        <NavLink to={"/signup"}>Create new account</NavLink>
        </div>
      </div>
      {/* <Link to="/posts">Posts</Link>
      <Link to="/login">Login</Link> */}
    </div>
  );
}
function mapState(state) {
  const { loggedIn, error } = state.authentication;
  return { loggedIn, error };
}
const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
};
const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };