import {HeaderTop} from './HeaderTop'
import {HeaderMain} from './HeaderMain'
import styles from './styles.module.css'
export const Header = (props) => {
    return (
        <header className={styles.header}>
            <HeaderTop userName={props.userName} />
            <HeaderMain userName={props.userName} />
        </header>
    )
}