import { useEffect, useRef } from 'react'; 
import styles from './styles.module.css';

export const Modal = (props) => { 
    let modalNode = useRef() 
    useEffect(()=>{
        let handler = (event) =>{ 
            if(!modalNode.current.contains(event.target)){ 
                props.onClose(false)
            }
        }
        if(props.visible){
        document.addEventListener("mousedown",handler);
        }
        return() => {
            document.removeEventListener("mousedown",handler);
        }
    },[props.visible])
    return(
        <div className={[styles.modalWrapper,props.visible?styles.showModal:''].join(' ')} >
            <div className={styles.modalInner} ref={modalNode}>
                 {props.children}
            </div>
        </div>
    )
}