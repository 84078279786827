import homeIcon  from '../../assets/icons/home.png';
import men  from '../../assets/icons/men.png'; 
import bhoup  from '../../assets/icons/bhoup.png';
import bell  from '../../assets/icons/bell.png';
import logout  from '../../assets/icons/logout.png';
import styles from './styles.module.css'
import { NavLink, useNavigate } from 'react-router-dom';
import { history } from '../../helpers/history'; 
import { useDispatch, useSelector } from 'react-redux';
import { userConstants } from '../../constants/user.constants';
export const HeaderMain = (props) =>{   
    const dispatch = useDispatch()
    const navigate = useNavigate()
    function LogoutFn(){
       dispatch({ type: userConstants.LOGOUT })
       navigate('/login')
    }
    return(
        <div className={styles.submenu}>
            <ul>
                <li>
                    <NavLink to={"/posts"}>
                        <img src={homeIcon} alt="Home" />
                    </NavLink> 
                </li> 
                <li>
                    <a href="#user"><img src={men} alt="User"  /></a>
                </li> 
                <li>
                    <NavLink to={"/createPost"} state={{ prevPath: history.location.pathname }}>
                        <img src={bhoup} alt="Loudst" className={styles.Loud_bhopu} />
                    </NavLink>
                </li> 
                <li className={styles.notify_icon}>
                    <a href="/showNotifications"><img src={bell} alt="Bell"  />
                        <div className={styles.countNotify} id="notificationsCount">2</div>
                    </a>
                </li> 
                <li> 
                     <p onClick={LogoutFn}><img src={logout} alt="logout"  /></p>
                </li>
            </ul>
        </div>
    )
}