// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_singlePost__sIMPl {

}
.styles_linkPreview__2AYg2 img {
    width: auto;
    margin:  0 auto;
    height: 300px; 
}
.styles_previewImg__ZTiC1{
    text-align: center;
}
.styles_postmages__UBqXC .styles_col2__3lI1V:nth-child(2) img{
    border-radius: 0 10px 0px 0;
    border: 2px solid #888;
    border-left-width: 1px;
}
.styles_postmages__UBqXC .styles_col2__3lI1V:nth-child(1) img{
    border-radius: 10px 0px 0px 0;
    border: 2px solid #888;
    border-right-width: 1px;
}
.styles_postTxt__OC2Lc {
   
    color: #000000;
    text-align: left;
    word-break: break-word;
    white-space: pre-line;
    line-height: 1.4;
}

.styles_postTxt__OC2Lc p{
    font-size: 14px;
    margin: 0;
}
.styles_postSec__dkGQr { 
    margin-bottom: 0;
    padding: 0 15px 15px;
    background: #e3e0da;
}
.styles_videoSec__jEDSL {text-align: center;    margin-bottom: 15px;}
.styles_videoSec__jEDSL video {
    width: auto;
    max-height: 500px;
    margin: 0 auto;
    text-align: center;
    max-width: 100%;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/PostFormats/BloudPost/styles.module.css"],"names":[],"mappings":"AAAA;;AAEA;AACA;IACI,WAAW;IACX,eAAe;IACf,aAAa;AACjB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,2BAA2B;IAC3B,sBAAsB;IACtB,sBAAsB;AAC1B;AACA;IACI,6BAA6B;IAC7B,sBAAsB;IACtB,uBAAuB;AAC3B;AACA;;IAEI,cAAc;IACd,gBAAgB;IAChB,sBAAsB;IACtB,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,SAAS;AACb;AACA;IACI,gBAAgB;IAChB,oBAAoB;IACpB,mBAAmB;AACvB;AACA,yBAAW,kBAAkB,KAAK,mBAAmB,CAAC;AACtD;IACI,WAAW;IACX,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,WAAW;AACf","sourcesContent":[".singlePost {\n\n}\n.linkPreview img {\n    width: auto;\n    margin:  0 auto;\n    height: 300px; \n}\n.previewImg{\n    text-align: center;\n}\n.postmages .col2:nth-child(2) img{\n    border-radius: 0 10px 0px 0;\n    border: 2px solid #888;\n    border-left-width: 1px;\n}\n.postmages .col2:nth-child(1) img{\n    border-radius: 10px 0px 0px 0;\n    border: 2px solid #888;\n    border-right-width: 1px;\n}\n.postTxt {\n   \n    color: #000000;\n    text-align: left;\n    word-break: break-word;\n    white-space: pre-line;\n    line-height: 1.4;\n}\n\n.postTxt p{\n    font-size: 14px;\n    margin: 0;\n}\n.postSec { \n    margin-bottom: 0;\n    padding: 0 15px 15px;\n    background: #e3e0da;\n}\n.videoSec {text-align: center;    margin-bottom: 15px;}\n.videoSec video {\n    width: auto;\n    max-height: 500px;\n    margin: 0 auto;\n    text-align: center;\n    max-width: 100%;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"singlePost": `styles_singlePost__sIMPl`,
	"linkPreview": `styles_linkPreview__2AYg2`,
	"previewImg": `styles_previewImg__ZTiC1`,
	"postmages": `styles_postmages__UBqXC`,
	"col2": `styles_col2__3lI1V`,
	"postTxt": `styles_postTxt__OC2Lc`,
	"postSec": `styles_postSec__dkGQr`,
	"videoSec": `styles_videoSec__jEDSL`
};
export default ___CSS_LOADER_EXPORT___;
