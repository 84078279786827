// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__w-inL {    max-width: 600px;
    margin: 0 auto;}

   .styles_pageWrap__qxU\\+L {
        position: relative;
    margin-top: 10px;
    height: calc(100vh - 80px);
    background: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 4px -1px rgb(0 0 0 / 45%);
    }`, "",{"version":3,"sources":["webpack://./src/screens/CreatePost/styles.module.css"],"names":[],"mappings":"AAAA,8BAAgB,gBAAgB;IAC5B,cAAc,CAAC;;GAEhB;QACK,kBAAkB;IACtB,gBAAgB;IAChB,0BAA0B;IAC1B,gBAAgB;IAChB,kBAAkB;IAClB,6CAA6C;IAC7C","sourcesContent":[".container {    max-width: 600px;\n    margin: 0 auto;}\n\n   .pageWrap {\n        position: relative;\n    margin-top: 10px;\n    height: calc(100vh - 80px);\n    background: #fff;\n    border-radius: 7px;\n    box-shadow: 0px 0px 4px -1px rgb(0 0 0 / 45%);\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__w-inL`,
	"pageWrap": `styles_pageWrap__qxU+L`
};
export default ___CSS_LOADER_EXPORT___;
