import styles from './styles.module.css'
import  { FaMicrophone, FaVideo, FaCamera, FaCog,FaWindowClose,FaTimes } from 'react-icons/fa';
import { Modal } from '../../modal';
import { useState,useRef } from 'react';
import { useEffect } from 'react';
import postServices from '../../../services/post.services';
import { useNavigate } from 'react-router';

export const BloudPost = (props) => {
    const [videoObj,setVideoObj]  = useState({
        'videofile':'',
        'videoName':'',
        'showvideo':false,
    });
    const [checkurl, setCheckurl] = useState([]); 
    const [isAlias, setIsAlias] = useState(false);  
    const [error,setError]  = useState(false)  
    const [linkPreview,setLinkPreview]  = useState({description:null,image:null,title:null,url:null})
    const [textVal,setTextVal]  = useState('') 
    const [charCount,setCharCount]  = useState(1000) 

    const videoRef = useRef(null);
    const imgRef = useRef(null);
    const formRef = useRef(null);
    const textArea = useRef(null);
    const navigate = useNavigate()

    function checkUrls(textBox) {
        var txt_arr = [];
        var txt = textBox;

        var changed_url = [];
        // if (txt.length == 0) {
        //     check_url = [];
        //     document.getElementById("previewLink" + num).innerHTML = "";
        // }
        var txt_arr = txt.replace(/\n/g, " ").split(" ");
        var exp = "((?:(http|https|Http|Https|rtsp|Rtsp):\\/\\/(?:(?:[a-zA-Z0-9\\$\\-\\_\\.\\+\\!\\*\\'\\(\\)"
            + "\\,\\;\\?\\&\\=]|(?:\\%[a-fA-F0-9]{2})){1,64}(?:\\:(?:[a-zA-Z0-9\\$\\-\\_"
            + "\\.\\+\\!\\*\\'\\(\\)\\,\\;\\?\\&\\=]|(?:\\%[a-fA-F0-9]{2})){1,25})?\\@)?)?"
            + "((?:(?:[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}\\.)+"
            + "(?:"
            + "(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])"
            + "|(?:biz|b[abdefghijmnorstvwyz])"
            + "|(?:cat|com|link|live|coop|c[acdfghiklmnoruvxyz])"
            + "|d[ejkmoz]"
            + "|(?:edu|e[cegrstu])"
            + "|f[ijkmor]"
            + "|(?:gov|g[abdefghilmnpqrstuwy])"
            + "|h[kmnrtu]"
            + "|(?:info|int|i[delmnoqrst])"
            + "|(?:jobs|j[emop])"
            + "|k[eghimnrwyz]"
            + "|l[abcikrstuvy]"
            + "|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])"
            + "|(?:name|net|n[acefgilopruz])"
            + "|(?:org|om)"
            + "|(?:pro|p[aefghklmnrstwy])"
            + "|qa"
            + "|r[eouw]"
            + "|s[abcdeghijklmnortuvyz]"
            + "|(?:tel|travel|t[cdfghjklmnoprtvwz])"
            + "|u[agkmsyz]"
            + "|v[aceginu]"
            + "|w[fs]"
            + "|y[etu]"
            + "|z[amw]))"
            + "|(?:(?:25[0-5]|2[0-4]"
            + "[0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\\.(?:25[0-5]|2[0-4][0-9]"
            + "|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\\.(?:25[0-5]|2[0-4][0-9]|[0-1]"
            + "[0-9]{2}|[1-9][0-9]|[1-9]|0)\\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}"
            + "|[1-9][0-9]|[0-9])))"
            + "(?:\\:\\d{1,5})?)"
            + "(\\S/(?:(?:[a-zA-Z0-9\\;\\/\\?\\:\\@\\&\\=\\#\\~"
            + "\\-\\.\\+\\!\\*\\'\\(\\)\\,\\_])|(?:\\%[a-fA-F0-9]{2}))*)?"
            + "(?:\\b|$)";
        var r = new RegExp(exp); 
        var checkForUrl = 0;

        changed_url = txt_arr.filter(ele => r.test(ele))
        

        for (var i = 0; i < txt_arr.length; i++) {


            if (r.test(txt_arr[i])) { 
                
                if (checkurl.indexOf(txt_arr[i]) == -1) { 
                    setCheckurl([...checkurl, txt_arr[i]])
                    var url = checkurl[checkurl.length - 1]; 

                } else { 
                    if (checkurl[checkurl.length - 1] != changed_url[changed_url.length - 1]) { 
                        setCheckurl(changed_url)
                    }
                }
            } else {
                if (checkurl.length > 0 && changed_url.length == 0) { 
                    setCheckurl([])
                }
            }
        }
    }

    useEffect(()=>{
       if(checkurl.length > 0){

            postServices.previewLink(checkurl[checkurl.length - 1]).then((resp)=>{ 
                if (!resp.err) {
                    setLinkPreview(resp); 
                    // setDisableInp(true) 
                  
                } else {

                }
            }); 
       } else{
            // setDisableInp(false)
            setLinkPreview({description:null,image:null,title:null,url:null})
       }
    },[checkurl])

    const toggleAlias = () =>{ 
        setIsAlias((prevState)=>{ if(prevState){return false;} else {return true;}})
    }



    const characterCount =(event) => { 
        checkUrls(event.target.value)
        if(event.target.value.length < 1000){ 
            setTextVal(event.target.value) 
        } 
    }
    useEffect(()=>{  
        if(charCount > 0){ 
        setCharCount(1000-textVal.length) 
        }

    },[textVal])

    const checkCharacterCount = (event) =>{
        let concatTxt = "";
        setTimeout(() => { 
            concatTxt = textArea.current.value  
            if(concatTxt.length < 1000){
                setTextVal(concatTxt) 
            } 
        }, 50);
    }
 
    useEffect(()=>{
        if(props.submitForm){
            submitHandler()
        }
    },[props.submitForm])





    const addPost= (postData)=>{ 
        postData.userId = props.userData.session_token
        postServices.addPost(postData).then((resp)=>{
            if(resp.status){
                navigate('/posts')
            }
        })
    }
    const submitHandler = () => { 
        setError(false)  
        if(!textVal){ 
            console.log('cannot be empty')
            setError(true)
            props.formFeedback()
        } else{ 
          
            let bloudData=
                {
                    "postType": 1,
                    "text": textVal,
                    "category": '',
                    "groupId": null,
                    "isAlias": isAlias,
                    "postGeoType": null,
                    "postGeoLat": "4254",
                    "postGeoLng": null,
                    "visibility": "public",
                    "video": "",
                    "videoThumbnail":'null',
                    "audio": null,
                    "images": [],
                    "linkPreviewDescription":linkPreview.description,
                   "linkPreviewImage":linkPreview.image,
                   "linkPreviewText":linkPreview.title,
                   "linkPreviewUrl":linkPreview.url
                }
                
            // if(linkPreview.linkPreviewUrl!==null){
            //     bloudData.linkPreview = linkPreview
            // } 
            
            if(props.imgFiles.current.files.length> 0){ 
                let imgFiles = [];
                let imgs = [...props.imgFiles.current.files] 
                let fileData = new FormData(); 
                imgs.forEach(img => {   
                    fileData.append('files',img)
                });
                postServices.uploadImage(fileData , props.userData.session_token).then((resp)=>{
                    if(resp.status){ 
                        imgFiles = resp.response
                        if(imgFiles.length == imgs.length){ 
                            bloudData.video = imgFiles;
                           addPost(bloudData)
                        }
                    }
                })
            }  if(props.videoFiles.current.files.length> 0){
                let vidFiles = [];
                let vids = [...props.videoFiles.current.files] 
                let fileDataVid = new FormData(); 
                vids.forEach(v => {   
                    console.log(v,'v')
                    fileDataVid.append('file',v)
                });
                postServices.uploadVideo(fileDataVid , props.userData.session_token).then((resp)=>{
                    if(resp.status){ 
                        vidFiles = resp.response.file
                            bloudData.video = vidFiles;
                            bloudData.videoThumbnail = 'null';
                          addPost(bloudData)
                        
                    }
                })
             } else {  
                console.log(bloudData)
               addPost(bloudData)
            } 
        }
        
    }
    
    return (
        <>
        <form  ref={formRef}>
        <div className={styles.bloudWrapper}>
            <div className={[styles.formWrap , error ?  styles.textError : ''].join(' ')}>
                <textarea id="bloudText" placeholder='Start a discussion - with your opinion, an article, an image, a video - just about anything' onChange={characterCount} onPaste={checkCharacterCount}  value={textVal} ref={textArea} />
            </div>
            <div className={styles.switchDiv}>
                <div className={styles.switch}>
                    <span>Post as:</span>
                    <label>
                        <small>{props.userData.name}</small>
                        <input type="checkbox" id="aliasSwitch" value={isAlias} onChange={toggleAlias}/>
                        <span className={styles.lever}></span>
                        <small>Alias</small>
                    </label>
                </div>
            </div>
 
          {linkPreview != null &&  <div id="previewLink1">
                <div className={styles.previewWrap}>
                    <a href={linkPreview.url}>
                        <div className={styles.preimage}>
                            <img src={linkPreview.image} alt="" />    
                        </div>
                        <div className={styles.preContent}>
                            <h6>{linkPreview.text}</h6>
                            <p>{linkPreview.description}</p>    
                        </div>
                    </a> 
                </div>
            </div> 
            }

        </div>
        </form>
        
        <span className={styles.charCount}>{charCount}</span>
        </>
    )
}
