import styles from './styles.module.css'
import { Modal } from '../../modal'
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import checked from '../../../assets/icons/checked.png'
import postServices from "../../../services/post.services";
import { FaInfoCircle } from 'react-icons/fa';
const PollPost = function (props) {
    const [modalShow, SetModalShow] = useState(false)
    const [pollData, setPollData] = useState('')
    const [pollId, setPollId] = useState('')



    function closeModal(e, modalState) {
        if (e) {
            e.preventDefault();
        }
        SetModalShow(modalState)
    }
    function addVote(user, post, poll) {
        if (props.postDetail.pollExpiry !== "Expired") {
            SetModalShow(true) 
            setPollId(poll)
        }
    }

    function listPolls(polls) {
        let pollsHTML = [];
        if (!polls.showResult) {
            {
                polls.polling.forEach((poll, i) => {
                    pollsHTML.push(
                        <div key={i} onClick={() => { addVote(props.postDetail.currUser, props.postDetail.postId, poll.pollId) }} className={[styles.singlePoll, styles.clickAble].join(' ')}>
                            <label>{poll.pollName}</label>
                        </div>
                    );

                })
            }
        } else {
            {
                polls.polling.forEach((poll, i) => {
                    pollsHTML.push(
                        <div key={i} className={[styles.singlePoll, poll.isVoted == 1 ? styles.pollVoted : null].join(' ')} >
                            <div className={styles.pollStatus}>
                                <div className={styles.fillPoll} style={{ width: poll.totalPollVoted + '%' }}></div>
                                <span className={styles.fillPer}>{poll.totalPollVoted}%</span>
                            </div>
                            <label>{poll.pollName}</label>
                        </div>
                    );
                })
            }
        }
        return pollsHTML;
    }
    function handleBtn(btn) {
        if (btn == 'yes') { 
            postServices.postPoll(props.postDetail.currUser, pollId)
                .then(resp => {
                    if (resp.status) { 
                        setPollData(resp.response.pollData)
                    }
                })
                .catch(err => {

                })
            SetModalShow(false)
        } else {
            SetModalShow(false)
        }
    }

    useEffect(()=>{ 
        console.log('props',props.postDetail)
        if(props.postDetail.pollData){
            setPollData(props.postDetail.pollData)
        }
    },[props.postDetail.pollData])
    return (
        <>
            <div className={[styles.singlePost,props.postDetail.isSinglePage ? styles.postInner : null].join(' ')}>
                <div className={styles.postSec}>
                    {props.postDetail.postText && <div className={styles.postTxt}>
                        <p>{props.postDetail.postText}</p>
                    </div>
                    }
                    <div className={styles.postPolls}>
                        {pollData && listPolls(pollData)}
                    </div>
                    <div className={styles.pollDetails}>
                        <div className={styles.voteCount}>
                            <span><img src={checked} /></span>
                            <p><b>{props.postDetail.pollData.pollExpiresDay}</b></p>
                        </div>
                    </div>
                </div>

            </div>
            <Modal visible={modalShow} onClose={(e) => { closeModal(e, false) }} >
                <h3>Sure, right?<br />
                    You cannot change your choice later.</h3>

                <div className={styles.buttons}>
                    <button onClick={() => { handleBtn('yes') }}> Yes </button>
                    <button onClick={() => { handleBtn('no') }}> No </button>
                </div>
            </Modal>
        </>
    )
}
export default PollPost;