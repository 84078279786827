// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_col2__vKWRD{
    width: 50%;
}
.styles_col1__Osu-V{
    width: 100%;
}
.styles_postmages__v8SGn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-top: -15px;
    margin-left: -15px;
    margin-right: -15px; */
    /* width: calc(100% + 30px); */
    margin-bottom: 0;
}
.styles_postmages__v8SGn.styles_postmages2__rrLF9 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    width: 100%;
    margin-bottom: 0;
}
.styles_postmages__v8SGn img {
    height: 300px;
    object-fit: cover;
    display: block;
    width: 100%;
}
.styles_postmages__v8SGn.styles_postmages2__rrLF9 img{height: auto!important;}
.styles_postmages__v8SGn .styles_col2__vKWRD:first-child img {
   /* padding-right: 10px;  */
   /* box-shadow: 0.5px 0px 0.5px 0px rgb(0 0 0 / 30%);
    width: calc(100% - 1px);
    margin-right: 1px; */
}
.styles_postmages__v8SGn .styles_col2__vKWRD:last-child img {
    /* padding-left: 10px;  */
    /* box-shadow: -0.5px 0px 0.5px 0px rgb(0 0 0 / 30%);
    width: calc(100% - 1px);
    margin-left: 1px; */
 }
.styles_postmages__v8SGn .styles_col1__Osu-V img{
width: 100%;
height: 300px;
object-fit: cover;
 }
 .styles_postVideo__Dqq8g {text-align: center;}
 .styles_postVideo__Dqq8g video {
    width: auto;
    display: block;
    max-width: 100%;
    max-height: 450px;
    margin: 0 auto;
 }
 .styles_linkPreviewDesc__rYnvw {
    padding: 10px;
 }`, "",{"version":3,"sources":["webpack://./src/components/PostFormats/postMedia/styles.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;IACI,WAAW;AACf;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B;;0BAEsB;IACtB,8BAA8B;IAC9B,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,SAAS;IACT,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,iBAAiB;IACjB,cAAc;IACd,WAAW;AACf;AACA,sDAA0B,sBAAsB,CAAC;AACjD;GACG,0BAA0B;GAC1B;;wBAEqB;AACxB;AACA;IACI,yBAAyB;IACzB;;uBAEmB;CACtB;AACD;AACA,WAAW;AACX,aAAa;AACb,iBAAiB;CAChB;CACA,0BAAY,kBAAkB,CAAC;CAC/B;IACG,WAAW;IACX,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,cAAc;CACjB;CACA;IACG,aAAa;CAChB","sourcesContent":[".col2{\n    width: 50%;\n}\n.col1{\n    width: 100%;\n}\n.postmages {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    /* margin-top: -15px;\n    margin-left: -15px;\n    margin-right: -15px; */\n    /* width: calc(100% + 30px); */\n    margin-bottom: 0;\n}\n.postmages.postmages2 {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin: 0;\n    width: 100%;\n    margin-bottom: 0;\n}\n.postmages img {\n    height: 300px;\n    object-fit: cover;\n    display: block;\n    width: 100%;\n}\n.postmages.postmages2 img{height: auto!important;}\n.postmages .col2:first-child img {\n   /* padding-right: 10px;  */\n   /* box-shadow: 0.5px 0px 0.5px 0px rgb(0 0 0 / 30%);\n    width: calc(100% - 1px);\n    margin-right: 1px; */\n}\n.postmages .col2:last-child img {\n    /* padding-left: 10px;  */\n    /* box-shadow: -0.5px 0px 0.5px 0px rgb(0 0 0 / 30%);\n    width: calc(100% - 1px);\n    margin-left: 1px; */\n }\n.postmages .col1 img{\nwidth: 100%;\nheight: 300px;\nobject-fit: cover;\n }\n .postVideo {text-align: center;}\n .postVideo video {\n    width: auto;\n    display: block;\n    max-width: 100%;\n    max-height: 450px;\n    margin: 0 auto;\n }\n .linkPreviewDesc {\n    padding: 10px;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"col2": `styles_col2__vKWRD`,
	"col1": `styles_col1__Osu-V`,
	"postmages": `styles_postmages__v8SGn`,
	"postmages2": `styles_postmages2__rrLF9`,
	"postVideo": `styles_postVideo__Dqq8g`,
	"linkPreviewDesc": `styles_linkPreviewDesc__rYnvw`
};
export default ___CSS_LOADER_EXPORT___;
