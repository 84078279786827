import styles from './styles.module.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {FaTrash} from 'react-icons/fa'
import { useState,useRef,useEffect } from 'react';
import postServices from '../../../services/post.services';
import { useNavigate } from 'react-router';
import { MobileDatePicker } from '@mui/x-date-pickers';
import TextField from "@mui/material/TextField";

export const PollPost = (props) => {  
    const navigate = useNavigate();
    const [charCount,setCharCount]  = useState(0)
    const [endDate, setEndDate] = useState(null);
    const [errors, setErrors] = useState([]);
    const [pollOptions, setpollOptions] = useState([{'option':1,'char':0,'val':''},{'option':2,'char':0,'val':''}]);
    const [textVal,setTextVal]  = useState('') 
    const textArea = useRef(null);
    const addOption = () => {
        setpollOptions((prevState)=>{ 
            return [...prevState,{'option':prevState.length+1,'char':0,val:''}]
        })
    }
    const removeOption = (ind) => {  
        setpollOptions((prevState)=>{
            let polls = [...prevState]
            polls.splice(ind, 1);
            return polls;
        })
    }
    const setValue = (e,i) =>{
        setpollOptions((prevState)=>{
            let polls = [...prevState]
            if(e.target.value.length < 41){
                polls[i].val = e.target.value;
                polls[i].char = e.target.value.length
            }
            return polls;
        })
    }
    function checkCharacterCount(){
        if(textArea.current.value.length < 501){
            setTextVal(textArea.current.value)
            setCharCount(textArea.current.value.length)
        }
    }
    const renderOptions = () => {
        let options = []
        pollOptions.forEach((ele,i) => { 
            options.push(<div key={i} className={[styles.pollInp , errors.includes('poll'+i) ? styles.error : ''].join(' ')}>
            <input  type="text" value={ele.val} onChange={(e)=>{setValue(e,i)}}  placeholder={'option '+ele.option} />
            <span>{ele.char}/40</span>
            {i>1 && <FaTrash onClick={()=>{removeOption(i)}} className={styles.deleteIcon} color={'#8c8d8d'} />}
            </div>
            )
        });{
        }
        return options;
    }
    const addPost= (postData)=>{
        postData.userId = props.userData.session_token
        postServices.addPost(postData).then((resp)=>{
            if(resp.status){
                navigate('/posts')
            }
        })
      
    }
    useEffect(()=>{
        props.formFeedback()
        
        if(props.submitForm){
            let errors = [];
            setErrors(errors);
            if(!textVal){
                errors.push('textarea');
            }
            if(!endDate){
                errors.push('endDate');
            }
            pollOptions.forEach((ele,i)=>{
                if(!ele.val){
                    errors.push('poll'+i);
                }
            })
            console.log('sub',errors)
            if(!errors.length){
                let pollsArr = [];
                pollOptions.forEach(ele=>{pollsArr.push(ele.val);})
                // console.log(endDate.$d.getDate(),'e')
                let mon;
                if(endDate.$d.getMonth() < 9){
                    mon='0'+(endDate.$d.getMonth()+1);
                } else{
                    mon=endDate.$d.getMonth()+1;
                }
                let dateString= endDate.$d.getFullYear()+'-'+(mon)+'-'+endDate.$d.getDate();
                let pollData = {
                    "postType": 2,
                    "text": textVal,
                    "shareUrl": "",
                    "category": "sports",
                    "groupId": null,
                    "isAlias": false,
                    "postGeoType": null,
                    "postGeoLat": "43435",
                    "postGeoLng": null,
                    "visibility": "public",
                    "pollData": pollsArr.join('@@'),
                    "pollExpiry": dateString,
                    "linkPreviewDescription":null,
                   "linkPreviewImage":null,
                   "linkPreviewText":null,
                   "linkPreviewUrl":null
                }
 
                console.log('pollData',pollData)
                addPost(pollData)
            } else {
                setErrors(errors)
            }
        }  
        
    },[props.submitForm])
    var  firstDay  = new Date();
    let minDate = firstDay.getTime() + 1 * 24 * 60 * 60 * 1000;
    let maxDate = firstDay.getTime() + 7 * 24 * 60 * 60 * 1000;
    return ( 
        <div className={styles.pollWrap}>
            <div className={styles.formWrap}>
                <form>
                    <div className={[styles.textBox, errors.includes('textarea') ? styles.error : ''].join(' ')}>
                        <textarea  onChange={checkCharacterCount}   onPaste={checkCharacterCount} ref={textArea} value={textVal} placeholder='Create a poll and find out what the world chooses - within four options'></textarea>
                        <span>{charCount}/500</span>
                    </div> 
                    <div className={[styles.datePicker,   errors.includes('endDate') ? styles.error : ''].join(' ')}>
                    <MobileDatePicker
                      
                        onChange={(newValue)=>{setEndDate(newValue)}}
                        value={endDate}
                        defaultValue={null}
                        minDate={minDate} maxDate={maxDate}
                        label={endDate=== null ? "Expiry date": ""}
                        renderInput={(params) => (
                        <TextField {...params} className="dateInp" InputLabelProps={{shrink: false}} />
                        )}
                    />
                    </div>
                          
                   
                    <div className={[styles.pollInputs, pollOptions.length > 2 ? styles.withDelete : ''].join(' ')}>
                        {renderOptions()}
                    </div>
                {pollOptions.length <4 && <div className={styles.addOption}>
                        <button onClick={(e)=>{e.preventDefault();addOption()}}>Add Option</button>
                    </div>
                    }
                    
                </form>
            </div>
    </div>   
    )
}