import { userConstants } from '../constants/user.constants';


const initialState = { loggedIn: false, user:{}  } ;

export function authentication(state = initialState, action) {

  switch (action.type) {
    
    case userConstants.LOGIN_SUCCESS:
      return { 
        loggedIn: action.loggedIn,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return {
          loggedIn: action.loggedIn,
          error:action.error
      };
    case userConstants.LOGOUT:
      return {
        loggedIn: false,
        user:{}
      };
    default:
      return state
  }
}