import styles from './styles.module.css' 

const BloudPost = function(props){ 
    let postTxt ="";
    if(props.postDetail.postText){
        if(props.postDetail.postText.length > 150){
            postTxt = props.postDetail.postText.substring(0,151) + '...';
        } else{
            postTxt = props.postDetail.postText;
        }
      
    }
   
     
    return(
        <>
            <div className={styles.singlePost}>
                <div className={styles.postSec}> 
                {props.postDetail.linkPreview && 
                    <div className={styles.linkPreview}>
                       {props.postDetail.linkPreview.image &&
                        <div className={styles.previewImg}> 
                            <img src={props.postDetail.linkPreview.image} />
                        </div>
                        }
                        <h2>{props.postDetail.linkPreview.text}</h2>
                        <p>{props.postDetail.linkPreview.description}</p>
                    </div>
                }
                 
                   
                    {postTxt && <div className={styles.postTxt}>
                    <p>{postTxt}</p>
                    </div>
                    }
                </div> 
            </div>
        </>
    )
}
export default BloudPost;