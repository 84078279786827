import React, {useState, useEffect, useCallback , useRef } from 'react'
import styles from './styles.module.css'
import './style.css'
import {FaLocationArrow, FaTimes} from 'react-icons/fa'
import {Modal}  from '../../modal/index'
import { MobileDatePicker , MobileTimePicker} from '@mui/x-date-pickers';
import TextField from "@mui/material/TextField";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import postServices from '../../../services/post.services';
import { useNavigate } from 'react-router';
export const ActPost = (props) => {
    const navigate = useNavigate();
    const [eventModal,seteventModal] = useState(false);
    const [expDate, setExpDate] = useState(null);
    const [fromTime, setFromTime] = useState(null);
    const [toTime, setToTime] = useState(null);
    const [registerTill, setRegisterTill] = useState(null);
    const [location, setLocation] = useState({lat:"",lng:""});
    const [address, setAddress] = useState(null);
    const [datePlaceholder, setDatePlaceholder] = useState('Petition expiry date'); 
    const [petitionPlaceholder, setPetitionPlaceholder] = useState('Minimum Petition Number'); 
    const [actType, setActType] = useState('online');
    const [error, setError] = useState(null);
    const [showMap, setShowMap] = useState(false);
    const apiKey = 'AIzaSyC2mbWRiPtpN0JK86Bhcg8QSScy22f59iA'; //
    const petitionTo = useRef(null);
    const subject = useRef(null);
    const eventDesc = useRef(null);
    const minimumPetition = useRef(null);
    function closeModal(e, modalState) {
        if(e){
            e.preventDefault();
        }
        seteventModal(modalState)
    }
   const setEventType = (e) =>{ 
        if(e.target.value=="online"){
            setDatePlaceholder('Petition expiry date') 
            setPetitionPlaceholder('Minimum Petition Number') 
        } else{
            setPetitionPlaceholder('Participants') 
            setDatePlaceholder('Event date') 
        }
        // closeModal('',false)
        setActType(e.target.value)
    }

    useEffect(()=>{
        props.formFeedback()
        
        if(props.submitForm){
            let errors = [];
           let subjecttxt = subject.current.value
           let eventDesctxt = eventDesc.current.value
           let petitionTotxt=null;
           let eventType = ""
           if(actType=="online"){
               petitionTotxt = petitionTo.current.value
               eventType = "online petition"
            } else{
               eventType = "offline event"
           }
           let minTarget = minimumPetition.current.value
            if(!errors.length){
                let fromTimeStrng = null
                let toTimeStrng = null
                let expDateStrng = null
                let registerTillStrng = null ;
                if(fromTime){ 
                    fromTimeStrng = (fromTime.$H < 9 ? '0'+fromTime.$H:fromTime.$H)+':'+(fromTime.$m < 9 ? '0'+fromTime.$m:fromTime.$m)+':00'; 
                }
                if(toTime){
                    toTimeStrng = (toTime.$H < 9 ? '0'+toTime.$H:toTime.$H)+':'+(toTime.$m < 9 ? '0'+toTime.$m:toTime.$m)+':00';
                }
                if(expDate){
                     expDateStrng = expDate.$y+'-'+(expDate.$M < 9?'0'+(expDate.$M+1) :(expDate.$M+1))+'-'+(expDate.$D < 9?'0'+expDate.$D :expDate.$D);
                }
                if(registerTill){ 
                    registerTillStrng = registerTill.$y+"-"+(registerTill.$M < 9?'0'+(registerTill.$M+1) :(registerTill.$M+1))+"-"+((registerTill.$D < 9?'0'+registerTill.$D :registerTill.$D))+" 0"+registerTill.$H+":0"+registerTill.$m+":00";
               }
                let actData = {
                    "eventAddress": address,
                    "groupId": null,
                    "video": null,
                    "videoThumbnail": 'null',
                    "postGeoType": null,
                    "eventReachLng": null,
                    "eventTimeFrom": fromTimeStrng,
                    "eventTimeTo": toTimeStrng,
                    "eventReachLat": null,
                    "eventName": subjecttxt,
                    "text": eventDesctxt,
                    "audio": null,
                    "offlineLastParticipateDate": registerTillStrng,
                    "eventReachRadius": null,
                    "images": null,
                    "visibility": "public",
                    "postType": 3,
                    "postGeoLng": null,
                    "eventType": eventType,
                    "eventExpiryDate": expDateStrng,
                    "onlinePetitionTo": petitionTotxt,
                    "isAlias": false,
                    "eventLat": location.lat  ? location.lat.toString():null,
                    "eventLng": location.lng  ? location.lng.toString():null,
                    "shareUrl": null,
                    "category": "sports",
                    "postGeoLat": null,
                    "minTarget": minTarget
                }
                if(props.imgFiles.current.files.length> 0){ 
                    let imgFiles = [];
                    let imgs = [...props.imgFiles.current.files] 
                    let fileData = new FormData(); 
                    imgs.forEach(img => {   
                        fileData.append('files',img)
                    });
                    postServices.uploadImage(fileData , props.userData.session_token).then((resp)=>{
                        if(resp.status){ 
                            imgFiles = resp.response
                            if(imgFiles.length == imgs.length){ 
                                actData.video = imgFiles;
                               addPost(actData)
                            }
                        }
                    })
                }  if(props.videoFiles.current.files.length> 0){
                    let vidFiles = [];
                    let vids = [...props.videoFiles.current.files] 
                    let fileDataVid = new FormData(); 
                    vids.forEach(v => {   
                        console.log(v,'v')
                        fileDataVid.append('file',v)
                    });
                    postServices.uploadVideo(fileDataVid , props.userData.session_token).then((resp)=>{
                        if(resp.status){ 
                            vidFiles = resp.response.file
                                actData.video = vidFiles;
                              addPost(actData)
                            
                        }
                    })
                 } else {  
                    console.log(actData)
                   addPost(actData)
                } 
            } else {
                // setErrors(errors)
            }
        }  
        
    },[props.submitForm])
    const addPost= (postData)=>{
        postData.userId = props.userData.session_token
        postServices.addPost(postData).then((resp)=>{
            if(resp.status){
                navigate('/posts')
            }
        })
      
    }
    const fetchAddress = async (lat, lng) => { 
        setShowMap(false)
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
        
        try {
            const response = await fetch(url);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.results.length > 0) {
              setAddress(data.results[0].formatted_address);
            } else {
              setAddress('No address found.');
            }
          } catch (error) {
            setError(`Error: ${error.message}`);
          }
            
      };

    

    const setLocationFn = (e) =>{
        
        if(e.target.value=="mylocation"){
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    fetchAddress(position.coords.latitude,position.coords.longitude)
                    setLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  });
                  closeModal(e,false)
                },
                (error) => {
                  setError(error.message);
                }
              );
              
        } else{
            navigator.geolocation.getCurrentPosition(
                (position) => { 
                    setLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  });
                  closeModal(e,false)
                  setShowMap(true)
                },
                (error) => {
                  setError(error.message);
                }
              );
             
             
        }
        
    }

   const isLoaded  = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiKey, // Replace with your Google Maps API key
      });
    const containerStyle = {
        width: '100%',
        height: '400px',
      };

    
  
      const onClick = useCallback((event) => {
        setLocation({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        });
      }, []);
      const  options ={
        mapTypeControl: false
      }
    return(
        <div className={styles.actWrap}>
            <div className={styles.formWrap}>
                <form>
                    <div className={styles.eventTypeSelector}>
                        <div className={styles.radioBtns}>
                            <ul>
                                <li><input  type="radio" value="online" name="eventType"  onChange={setEventType}  id='online' checked={actType === 'online'} /><label htmlFor="online">online</label></li>
                                <li><input  type="radio" value="offline" name="eventType" onChange={setEventType} id='offline' checked={actType === 'offline'} /><label htmlFor="offline">offline</label></li>
                            </ul>
                        </div>
                    </div>
                   {actType=="online" && <div className={styles.formInput}>
                        <input type="text" name='petitionTo' ref={petitionTo} placeholder='Petition to:' />
                    </div>}
                   <div className={styles.formInput}>
                   <input type="text" ref={subject} placeholder='A short but clear subject for your act' name='subject' />
                   </div>
                   <div className={styles.formInput}>
                   <textarea placeholder='Organise an event - meeting, march, online petition etc. Mention as much details as possible' name='eventDesc' ref={eventDesc}></textarea>
                   </div>
                   <>
                     
                            <div className={styles.datePicker}>
                            <MobileDatePicker
                                onChange={(newValue)=>{setExpDate(newValue)}}
                                value={expDate}
                                defaultValue={null}
                                label={expDate=== null ? datePlaceholder: ""}
                                renderInput={(params) => (
                                <TextField {...params} className="dateInp" InputLabelProps={{shrink: false}} />
                                )}
                            />
                            </div>
                    
                    {actType=="offline" &&  
                        <>
                            <div className={styles.timePicker}>
                                <MobileTimePicker 
                                    onChange={(newValue)=>{setFromTime(newValue)}}
                                     value={fromTime}
                                     defaultValue={null}
                                     label={fromTime=== null ? "Event time from": ""}
                                    renderInput={(params)=>( 
                                    <TextField {...params} className="dateInp"  InputLabelProps={{shrink: false}}  />
                                    )}/> 
                            </div>
                             <div className={styles.timePicker}>
                             <MobileTimePicker 
                                 onChange={(newValue)=>{setToTime(newValue)}}
                                  value={toTime}
                                  defaultValue={null}
                                  label={toTime=== null ? "To": ""}
                                 renderInput={(params)=>( 
                                 <TextField {...params} className="dateInp"  InputLabelProps={{shrink: false}}  />
                                 )}/> 
                            </div> 
                            <div className={styles.datePicker}>
                            <MobileDatePicker
                                onChange={(newValue)=>{setRegisterTill(newValue)}}
                                value={registerTill}
                                defaultValue={null}
                                label={registerTill=== null ? 'Register till': ""}
                                renderInput={(params) => (
                                <TextField {...params} className="dateInp" InputLabelProps={{shrink: false}} />
                                )}
                            />
                            </div>
                            <div className={styles.eventType} onClick={()=>{seteventModal(true)}}>
                                <FaLocationArrow color="#039be5" />
                              {address &&  <span>{address}</span>}
                            </div>
                         </>
                    }
                    </>
                     <div className={styles.formInput}>
                     <input type="text" name="minimumPetition" placeholder={petitionPlaceholder} ref={minimumPetition} />
                     </div>
                </form>
            </div>
          {showMap &&  <div className={styles.mapDiv}>
                {isLoaded && location ? (
                    <>
                    <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={location}
                    zoom={12}
                    options={options}
                    onClick={onClick}
                    >
                    {location && <Marker position={location} />}
                    </GoogleMap>
                    <div className={styles.btns}>
                        <button onClick={()=>{fetchAddress(location.lat,location.lng)}}>SET</button>
                    </div>
                    </>
                ) : (
                    <div>Loading...</div>
                )}
            </div>
            }
            <Modal visible={eventModal}  onClose={(e) => { closeModal(e,false) }}>
            <div className={styles.modalInner}>
                    <div className={styles.modalHeader}>
                        <a href="#" onClick={(e) => { closeModal(e, false) }} className={styles.closeModal} ><FaTimes color="#616060" size="25px" /></a>
                        <h2>Event Location</h2>
                      
                    </div>
                   <div className={styles.modalContent}>
                   <ul className={styles.eventTypesSelection}>
                            <li>  
                                <input type="radio" name="eventType" id="mylocation" value="mylocation" onChange={(e)=>{setLocationFn(e)}} />
                                <label htmlFor="mylocation">Get my location</label>
                            </li>
                            <li>  
                                <input type="radio" name="eventType"  value="otherlocation" id="otherlocation"  onChange={(e)=>{setLocationFn(e)}}/>
                                <label htmlFor="otherlocation">Choose other location</label>
                            </li>
                          
                        </ul>
                   </div>
                </div>
            </Modal>
        </div>  
    )
}