import styles from './styles.module.css'
import logo  from '../../assets/icons/logo_white.png';
export const  HeaderTop = (props) => {  
    return(
        <div className={styles.topHeader}>
            <div className={styles.logoSec}>
                <img className={styles.siteLogo} src={logo} alt="" />
                <div className={styles.userName}>
                <span> Welcome</span>
                {props.userName && <span>, {props.userName}</span>}
                {!props.userName && <span>, Guest</span>}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.searchBox}>
                    <form action="/post/postSearch">
                        <input type="text" placeholder="Search" />
                        <input type="submit" value="" />
                    </form>
                    <span className={styles.searchClose}></span>
                </div>
            </div>
        </div>
    )
}