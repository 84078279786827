import { userConstants } from '../constants/user.constants'
import { userServices } from '../services';
import { alertActions } from './alert.actions';
import { history } from '../helpers/history';
import { Redirect } from "react-router";
export const userActions = {
    login,
    logout, 
    loginPopup
};

function login(user) {
    return dispatch => { 
        dispatch(loginSuccess(true,user));
    };
 
    function loginSuccess(loggedIn,user) { return { type: userConstants.LOGIN_SUCCESS, loggedIn,user } } 
} 
function loginPopup() {
    return dispatch => { 
        dispatch(showPopup(true));
    };
 
    function showPopup(popup) { return { type: userConstants.SHOW_POPUP, popup } } 
} 
function logout() {
    userServices.logout();
    return { type: userConstants.LOGOUT };
}


