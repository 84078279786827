// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dateInp fieldset.MuiOutlinedInput-notchedOutline {
    border:none;
  }
  .dateInp {width: 100%;}
.dateInp .MuiOutlinedInput-root {
    border:none;
    width: 100%;
  }
  .dateInp label {font-size: 14px;color:#8c8d8d}
  .dateInp input {
    width: 100%;
    background: #f0ecec!important;
    border: none;
    padding: 15px;
    height: auto;
    font-size: 14px;
    margin-bottom: 15px;
    line-height: 1;
    border-radius: 3px;
  }`, "",{"version":3,"sources":["webpack://./src/components/createPostFormat/Act/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;EACb;EACA,UAAU,WAAW,CAAC;AACxB;IACI,WAAW;IACX,WAAW;EACb;EACA,gBAAgB,eAAe,CAAC,aAAa;EAC7C;IACE,WAAW;IACX,6BAA6B;IAC7B,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,cAAc;IACd,kBAAkB;EACpB","sourcesContent":[".dateInp fieldset.MuiOutlinedInput-notchedOutline {\n    border:none;\n  }\n  .dateInp {width: 100%;}\n.dateInp .MuiOutlinedInput-root {\n    border:none;\n    width: 100%;\n  }\n  .dateInp label {font-size: 14px;color:#8c8d8d}\n  .dateInp input {\n    width: 100%;\n    background: #f0ecec!important;\n    border: none;\n    padding: 15px;\n    height: auto;\n    font-size: 14px;\n    margin-bottom: 15px;\n    line-height: 1;\n    border-radius: 3px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
