import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import styles from "./styles.module.css";
import { postActions } from "../../actions/posts.actions";
import { PostAuthor } from "../../components/PostFormats/PostAuthor";
import BloudPost from "../../components/PostFormats/BloudPost";
import PollPost from "../../components/PostFormats/PollPost";
import { useParams } from "react-router-dom";
import EventPost from "../../components/PostFormats/EventPost";
import arrow_left from "../../assets/icons/arrow_left.png";
import agreeBtn from "../../assets/icons/likeIcon.png";
import disagreeBtn from "../../assets/icons/dislikeIcon.png";
import defaultImg from "../../assets/icons/user-default.png";
import sendBtnImg from "../../assets/icons/send.png";
import userImgDefault from "../../assets/icons/user-bloud.png";
import imgBck from "../../assets/icons/img_back.png";
import PostMedia from "../../components/PostFormats/postMedia";
import PostStance from "../../components/PostFormats/postStance";
import {LoginPopup } from "../../components/loginPopup/index"
import {
  FaRegClock,
  FaThumbsUp,
  FaThumbsDown,
  FaTrashAlt,
  FaStar,
  FaRegStar,
} from "react-icons/fa";
import { BsReply, BsStar } from "react-icons/bs";
import { CiTrash } from "react-icons/ci";
import { RiStackFill } from "react-icons/ri";

import postServices from "../../services/post.services";
import { FaChevronDown } from "react-icons/fa";
import { tr } from "date-fns/locale";
// import {Header} from '../../components/Headers'
const PostSingle = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const data = useLocation();
  const id = params.id;
  const [videoObj, setVideoObj] = useState({
    videofile: "",
    videoName: "",
    showvideo: false,
  });
  const [showFilter, setShowFilter] = useState(false);
  const [imgObj, setImgObj] = useState({ imgFile: [], showImg: false });
  const [postData, setPostData] = useState(null);
  const [comments, setComments] = useState([]);
  const [isAlias, setIsAlias] = useState(0);
  const [textareaObj, setTextareaObj] = useState({
    visible: false,
    placeholder: "",
  });
  const [commentTab, setcommentTab] = useState({
    activeTab: "all",
    commentsData: [],
  });
  const [charCount, setCharCount] = useState(1000);
  const [textVal, setTextVal] = useState("");
  const [checkurl, setCheckurl] = useState([]);
  const videoRef = useRef(null);
  const imgRef = useRef(null);
  const textArea = useRef(null);
  const replyTextRef = useRef(null);
  const [showLoginPopup, setshowLoginPopup] = useState(false);
  const [replyPopup, setReplyPopup] = useState({
    show: false,
    name: "",
    commentTxt: "",
    commentType: "",
    cid: "",
  });
  const [parentId, setParentId] = useState(null);
  const toggleAlias = () => {
    setIsAlias((prevState) => {
      if (prevState == 0) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  function toggleLoginPopup(e){ 
    setshowLoginPopup(e)
   }
  const characterCount = (event) => {
    checkUrls(event.target.value.trim());
    if (event.target.value.trim().length < 1000) {
      if (event.target.value.trim().length == 0) {
        setTextVal(event.target.value.trim());
      } else {
        setTextVal(event.target.value);
      }
    }
  };
  function reloadComments(data, type = "all") {
    if (data) {
      setShowFilter(false);
      getComments(type, id, props.authentication.user.session_token);
    }
  }
  useEffect(() => {
    if (charCount > 0) {
      setCharCount(1000 - textVal.length);
    }
  }, [textVal]);
  function checkUrls(textBox) {
    var txt_arr = [];
    var txt = textBox;

    var changed_url = [];
    // if (txt.length == 0) {
    //     check_url = [];
    //     document.getElementById("previewLink" + num).innerHTML = "";
    // }
    var txt_arr = txt.replace(/\n/g, " ").split(" ");
    var exp =
      "((?:(http|https|Http|Https|rtsp|Rtsp):\\/\\/(?:(?:[a-zA-Z0-9\\$\\-\\_\\.\\+\\!\\*\\'\\(\\)" +
      "\\,\\;\\?\\&\\=]|(?:\\%[a-fA-F0-9]{2})){1,64}(?:\\:(?:[a-zA-Z0-9\\$\\-\\_" +
      "\\.\\+\\!\\*\\'\\(\\)\\,\\;\\?\\&\\=]|(?:\\%[a-fA-F0-9]{2})){1,25})?\\@)?)?" +
      "((?:(?:[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}\\.)+" +
      "(?:" +
      "(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])" +
      "|(?:biz|b[abdefghijmnorstvwyz])" +
      "|(?:cat|com|link|live|coop|c[acdfghiklmnoruvxyz])" +
      "|d[ejkmoz]" +
      "|(?:edu|e[cegrstu])" +
      "|f[ijkmor]" +
      "|(?:gov|g[abdefghilmnpqrstuwy])" +
      "|h[kmnrtu]" +
      "|(?:info|int|i[delmnoqrst])" +
      "|(?:jobs|j[emop])" +
      "|k[eghimnrwyz]" +
      "|l[abcikrstuvy]" +
      "|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])" +
      "|(?:name|net|n[acefgilopruz])" +
      "|(?:org|om)" +
      "|(?:pro|p[aefghklmnrstwy])" +
      "|qa" +
      "|r[eouw]" +
      "|s[abcdeghijklmnortuvyz]" +
      "|(?:tel|travel|t[cdfghjklmnoprtvwz])" +
      "|u[agkmsyz]" +
      "|v[aceginu]" +
      "|w[fs]" +
      "|y[etu]" +
      "|z[amw]))" +
      "|(?:(?:25[0-5]|2[0-4]" +
      "[0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\\.(?:25[0-5]|2[0-4][0-9]" +
      "|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\\.(?:25[0-5]|2[0-4][0-9]|[0-1]" +
      "[0-9]{2}|[1-9][0-9]|[1-9]|0)\\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}" +
      "|[1-9][0-9]|[0-9])))" +
      "(?:\\:\\d{1,5})?)" +
      "(\\S/(?:(?:[a-zA-Z0-9\\;\\/\\?\\:\\@\\&\\=\\#\\~" +
      "\\-\\.\\+\\!\\*\\'\\(\\)\\,\\_])|(?:\\%[a-fA-F0-9]{2}))*)?" +
      "(?:\\b|$)";
    var r = new RegExp(exp);
    var checkForUrl = 0;

    changed_url = txt_arr.filter((ele) => r.test(ele));

    for (var i = 0; i < txt_arr.length; i++) {
      if (r.test(txt_arr[i])) {
        if (checkurl.indexOf(txt_arr[i]) == -1) {
          setCheckurl([...checkurl, txt_arr[i]]);
          var url = checkurl[checkurl.length - 1];
        } else {
          if (
            checkurl[checkurl.length - 1] != changed_url[changed_url.length - 1]
          ) {
            setCheckurl(changed_url);
          }
        }
      } else {
        if (checkurl.length > 0 && changed_url.length == 0) {
          setCheckurl([]);
        }
      }
    }
  }

  const checkCharacterCount = (event) => {
    let concatTxt = "";
    setTimeout(() => {
      concatTxt = textArea.current.value.trim();
      if (concatTxt.length < 1000) {
        setTextVal(concatTxt);
      }
    }, 50);
  };
  useEffect(() => {
    if (props.authentication.user.session_token) {
      getPostDataSingle(props.authentication.user.session_token);
      getComments("all", id, props.authentication.user.session_token);
    }
  }, [props.authentication.user.session_token]);

  const getPostDataSingle = (uid) => {
    postServices
      .getPostItem(id, uid)
      .then((resp) => {
        if (resp.status) {
          let respData = resp.response;
          respData.isSinglePage = true;
          setPostData(respData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getComments = (type, pid, uid) => {
    postServices
      .getComments(type, pid, uid)
      .then((resp) => {
        if (resp.status) {
          setComments(resp.response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const singlePost = (post) => {
    post.currUser = props.authentication.user.id;
    let videoObj = { video: post.video, videoThumbnail: post.videoThumbnail };
    let linkObj = {
      linkPreviewImage: post.linkPreviewImage,
      linkPreviewText: post.linkPreviewText,
      linkPreviewDescription: post.linkPreviewDescription,
      linkPreviewUrl: post.linkPreviewUrl,
    };
    let images = [];

    if (post.linkPreviewImage) {
      images.push(post.linkPreviewImage);
    } else if (post.images) {
      images = post.images;
    }
    if (post.postType == 1) {
      return (
        <div className={styles.postWrap} key={post._id}>
          {(images.length > 0 || videoObj.video) && (
            <PostMedia
              postMedia={images}
              postVideo={videoObj}
              linkObj={linkObj}
            />
          )}
          <PostAuthor post={post} />
          <BloudPost postDetail={post} />
        </div>
      );
    } else if (post.postType == 2) {
      return (
        <div className={styles.postWrap} key={post._id}>
          {(images.length > 0 || videoObj.video) && (
            <PostMedia
              postMedia={images}
              postVideo={videoObj}
              linkObj={linkObj}
            />
          )}
          <PostAuthor post={post} />
          <PollPost postDetail={post} />
        </div>
      );
    } else if (post.postType == 3) {
      return (
        <div className={styles.postWrap} key={post._id}>
          <PostAuthor post={post} />
          {post.postText && (
            <div className={styles.postTxt}>
              <p>{post.postText}</p>
            </div>
          )}
          {(images.length > 0 || videoObj.video) && (
            <PostMedia
              postMedia={images}
              postVideo={videoObj}
              linkObj={linkObj}
            />
          )}
          <EventPost postDetail={post} />
        </div>
      );
    }
  };

  const commentTemplate = (
    commentData,
    level,
    isLast = false,
    parentStance
  ) => {
    let stars = [];
    let userRate = commentData.login_user_rating;
    for (let i = 1; i <= userRate; i++) {
      stars.push(<FaStar color="#f00000" />);
    }
    if (userRate) {
      for (let i = 1; i <= 5 - userRate; i++) {
        stars.push(<FaRegStar color="#707070" />);
      }
    } else {
      for (let i = 1; i <= 5; i++) {
        stars.push(
          <FaRegStar
            color="#707070"
            className={styles.clickable}
            onClick={() => {
              commentRating(commentData.id, i);
            }}
          />
        );
      }
    }
    return (
      <div
        className={[
          styles.commentBox,
          styles[parentStance],
          styles[level],
          isLast ? styles.isLast : null,
        ].join(" ")}
        key={commentData.id}
      >
        <div className={styles.leftSec}>
          <img className={styles.backImg} src={imgBck} alt="" />
          <img className={styles.user_img}
            src={
              commentData.user_photo ? commentData.user_photo : userImgDefault
            }
            alt=""
          />
        </div>
        <div className={styles.rightSec}>
          <div className={styles.commWrap}>
            <div className={styles.titleWrap}>
              <div className={styles.headLeft}>
                {commentData.userStancePost == "agree" ? (
                  <FaThumbsUp color="#6fbb61" />
                ) : (
                  <FaThumbsDown color="#b1492e" />
                )}
                <span className={styles.title}>{commentData.user_name}</span>
              </div>
              <div className={styles.headRight}>
                <div className={styles.commentTime}>
                  <FaRegClock />
                  <span>{commentData.timeAgo}</span>
                </div>
              </div>
            </div>
            <p className={[
                styles.commentText,
                commentData.is_delete ? styles.isDeleted : null,
              ].join(" ")}
            >
              {!commentData.is_delete && commentData.text}
              {commentData.is_delete && "this argument is deleted"}
            </p>
          </div>
          {!commentData.is_delete && (
            <div className={styles.commentsFooter}>
              <div className={styles.footerLeft}>
                <div
                  className={styles.replyBtn}
                  onClick={() => {
                    replyComment(commentData.id);
                  }}
                >
                  {commentData.can_replay && <BsReply fill="#707070" />}
                </div>
                <div className={styles.deleteBtn}
                  onClick={() => {
                    deleteComment(commentData.id);
                  }}
                >
                  {commentData.can_delete && <CiTrash fill="#ff0000" />}
                </div>
              </div>

              <div className={styles.rating}>
                {commentData.can_rating && (
                  <div className={styles.stars}>{stars}</div>
                )}
                <div className={styles.rated}>
                  {commentData.rating_weightage_label}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  const commentRating = (cid, rating) => {
    postServices
      .commentRating(cid, rating, id, props.authentication.user.session_token)
      .then((resp) => {
        if (resp.status) {
          reloadComments(resp.status);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  function deleteComment(cid) {
    postServices
      .deleteComment(cid, props.authentication.user.session_token)
      .then((resp) => {
        if (resp.status) {
          reloadComments(resp.status);
        }
      })
      .catch((e) => {});
  }

  const replyComment = (id) => {
    setParentId(id);
    // let pComm = {};
    // if (level == 'level2') {
    //     pComm = postData.allComments.find(e => e._id == pid);
    //     pComm = pComm.commentReply.find(e => e._id == id);
    // } else {
    //     pComm = postData.allComments.find(e => e._id == id);
    // }
    // setReplyPopup((prev) => {
    //     return { show: true, name: pComm.user.name, commentTxt: pComm.text.slice(0, 90), cid: id, commentType: pComm.commentType }
    // })
  };

  const addReply = () => {
    let replyText = replyTextRef.current.value.trim();
    if (replyText) {
      let body = {
        text: replyText,
        name: props.authentication.user.name,
        postId: postData._id,
        isAlias: isAlias,
        lpDesc: "",
        lpImg: "",
        lpText: "",
        lpURL: "",
        _id: props.authentication.user._id,
        commentType: replyPopup.commentType == "AGREE" ? 1 : 2,
        commentId: replyPopup.cid,
        profileImage: props.authentication.user.profileImage,
      };

      fetch("http://localhost:8080/addReplyTemp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.isSuccess == 1) {
            getPostDataSingle("level2");
          }
        });
    }
  };

  const closeReply = () => {
    setReplyPopup((prev) => {
      return false;
    });
  };
  const commentsList = (commentData) => {
    let commList = [];
    if (commentData) {
      commentData.forEach((comm) => {
        commList.push(
          commentTemplate(comm, "level0", false, comm.userStancePost)
        );
        if (comm.threads.length > 0) {
          comm.threads.forEach((r, i) => {
            let isLast = false;
            if (comm.threads.length - 1 == i) {
              isLast = true;
            }
            commList.push(
              commentTemplate(r.level1, "level1", isLast, comm.userStancePost)
            );
          });
        }
      });
    }
    return commList;
  };
  const changeTab = (activeTab) => {
    if (activeTab == "all") {
      setcommentTab((prev) => {
        return {
          activeTab: activeTab,
          commentsData: commentsList(postData.allComments),
        };
      });
    } else if (activeTab == "agree") {
      setcommentTab({
        activeTab: activeTab,
        commentsData: commentsList(postData.positiveComments),
      });
    } else {
      setcommentTab({
        activeTab: activeTab,
        commentsData: commentsList(postData.negativeComments),
      });
    }
  };

  const backBtn = () => {
    if (data.state !== null && data.state.prevPath == "posts") {
      navigate(-1);
    } else {
      navigate("/posts");
    }
  };
  const showSortFilter = () => {
    setShowFilter((prevState) => !prevState);
  };
  return (
    <>
      {postData && (
        <div className={styles.pageWrap}>
          <div className={styles.singleHeader}>
            <div className={styles.headerWrap}>
              <img onClick={backBtn} src={arrow_left} />
              <div className={styles.postdetailHeader}>
                <h1>{postData.postText}</h1>
                <div className={styles.voteCount}>
                  <div className={styles.agreeCount}>
                    <span>Agree : {postData.isAgree}</span>
                  </div>
                  <span>&nbsp;|&nbsp;</span>
                  <div className={styles.disagreeCount}>
                    <span>Disagree : {postData.disagreeCount}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.postContainer}>
            {singlePost(postData)}
            <div className={styles.commentSelector}>
              <button
                onClick={() => {
                  showSortFilter();
                }}
              >
                All Comments ({comments && comments.length}){" "}
                <FaChevronDown size="15px" />
              </button>
            </div>
            <div className={styles.commentsList}>
              {postData.commentsCount == 0 && (
                <p>Be the first commenter by taking a stance</p>
              )}
              {comments.length > 0 && commentsList(comments)}
            </div>
            <PostStance
              postDetail={postData}
              commentSuccess={reloadComments}
              setLoginPopup={toggleLoginPopup}
              parentId={parentId}
            />
            <div
              className={[
                styles.commentsSection,
                postData.postType == 2 && postData.pollExpiry != "Expired"
                  ? styles.sectionInactive
                  : null,
              ].join(" ")}
            >
              {textareaObj.visible && (
                <div className={styles.commentInputBox}>
                  <textarea
                    placeholder={textareaObj.placeholder}
                    onChange={characterCount}
                    onPaste={checkCharacterCount}
                    value={textVal}
                    ref={textArea}
                  ></textarea>
                  <div className={styles.switchDiv}>
                    <div className={styles.switch}>
                      <span>Post as:</span>
                      <label>
                        <small>{props.authentication.user.name}</small>
                        <input
                          type="checkbox"
                          id="aliasSwitch"
                          value={isAlias}
                          onChange={toggleAlias}
                        />
                        <span className={styles.lever}></span>
                        <small>Alias</small>
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className={[
                styles.sortContainer,
                showFilter ? styles.slidein : "",
              ].join(" ")}
            >
              <div
                className={styles.filterAll}
                onClick={() => {
                  reloadComments(true, "stackUser");
                }}
              >
                All Comments
              </div>
              <div className={styles.filterSec}>
                <strong>Filter</strong>
                <div
                  className={[styles.filterBtns].join(" ")}
                  onClick={() => {
                    reloadComments(true, "agree");
                  }}
                >
                  <FaThumbsUp color="#6eb85f" /> <span>Agree</span>
                </div>
                <div
                  className={[styles.filterBtns].join(" ")}
                  onClick={() => {
                    reloadComments(true, "disagree");
                  }}
                >
                  <FaThumbsDown color="#b9452e" /> <span>Disagree</span>
                </div>
              </div>
              <div className={styles.filterSec}>
                <strong>Sort</strong>
                <div
                  className={styles.filterBtns}
                  onClick={() => {
                    reloadComments(true, "highRating");
                  }}
                >
                  <FaStar color="#ffff00" className={styles.starIcon} />{" "}
                  <span>Most High Rating</span>
                </div>
                <div
                  className={styles.filterBtns}
                  onClick={() => {
                    reloadComments(true, "mostReplied");
                  }}
                >
                  <BsReply /> <span>Most replies</span>
                </div>
              </div>
              <div
                className={styles.filterSec}
                onClick={() => {
                  reloadComments(true, "stackUser");
                }}
              >
                <strong>View</strong>
                <div className={styles.filterBtns}>
                  <RiStackFill color="#6178f8" /> <span>Stacked by User</span>
                </div>
              </div>
            </div>
          </div>
          {replyPopup.show && (
            <div className={styles.replyInputWrap}>
              <div className={styles.replyInputBox}>
                <h2>{replyPopup.name}</h2>
                <div className={styles.closeBtn} onClick={closeReply}>
                  X
                </div>
                <p>{replyPopup.commentTxt}...</p>
                <div className={styles.replyForm}>
                  <textarea ref={replyTextRef} />
                  <button onClick={addReply}>
                    <img src={sendBtnImg} alt="" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
       <div className={[styles.loginPopup,showLoginPopup ? styles.slideIn : ''].join(' ')}>
        <LoginPopup setLoginPopup={toggleLoginPopup}  />
      </div>
    </>
  );
};

function mapState(state) {
  const { authentication, alert } = state;
  return { authentication, alert };
}
const actionCreators = {
  postPoll: postActions.polling,
  eventConfirmation: postActions.eventConfirmation,
};
const connectedPostSingle = connect(mapState, actionCreators)(PostSingle);
export { connectedPostSingle as PostSingle };
