// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/fonts/MaisonNeue-Book.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("assets/fonts/MaisonNeue-Book.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: MaisonNeue-Book;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}),
   url(${___CSS_LOADER_URL_REPLACEMENT_1___}),

}
body {
  font-family: "MaisonNeue-Book";
  background: #F3F3F3;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B;;;AAGF;AACA;EACE,8BAA8B;EAC9B,mBAAmB;AACrB","sourcesContent":["@font-face {\n  font-family: MaisonNeue-Book;\n  src: url(assets/fonts/MaisonNeue-Book.ttf),\n   url(assets/fonts/MaisonNeue-Book.woff),\n\n}\nbody {\n  font-family: \"MaisonNeue-Book\";\n  background: #F3F3F3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
